/**
 * ChapterNavigation
 * @module components/ChapterNavigation
 */

import React from 'react';
import { Wrapper, Line } from './ChapterNavigation.styles';

interface PropsInterface {
	children: any;
}

const ChapterNavigation: React.FC<PropsInterface> = props => {
	const { children } = props;

	return (
		<Line>
			<Wrapper>{children}</Wrapper>
		</Line>
	);
};

export default ChapterNavigation;
