/**
 * AutoComplete
 * @module components/AutoComplete
 */

import React, { useState, useRef } from 'react';
import { useOrgAutocomplete } from 'applications/survey/api';
import Downshift from 'downshift';
import {
	FieldWrapper,
	Input,
	Label,
	InputLoader,
	List,
	ListItem,
} from './IdentificationForm.styles';

interface PropsInterface {
	onChange: any;
}

interface acDataInterface {
	name: string;
	typeId: string;
	typeName: string;
	sizeClassId: string;
	sizeClassName: string;
	custom?: boolean;
}

interface acRequestInterface {
	data: acDataInterface[];
	[otherProps: string]: any;
}

const cantFindSuggestion = {
	name: 'Jag kan inte hitta min organisation',
	typeId: 'CANTFIND',
	typeName: 'CANTFIND',
	sizeClassId: 'CANTFIND',
	sizeClassName: 'CANTFIND',
	custom: true,
};

const wontShareSuggestion = {
	name: 'Jag önskar fylla i typ och storlek manuellt',
	typeId: 'WONTSHARE',
	typeName: 'WONTSHARE',
	sizeClassId: 'WONTSHARE',
	sizeClassName: 'WONTSHARE',
	custom: true,
};

const AutoComplete: React.FC<PropsInterface> = props => {
	const [query, setQuery] = useState('');
	const [suggestions, error, loading] = useOrgAutocomplete(query);
	const { onChange } = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const filteredSuggestions = [
		...suggestions.slice(0, 5),
		cantFindSuggestion,
		wontShareSuggestion,
	];

	const onAcChange = (selected: acDataInterface) => {
		if (selected) {
			onChange(selected);
			if (inputRef && inputRef.current) {
				inputRef.current.blur();
			}
		}
	};

	const onInputValueChange = (inputValue: string, stateAndItems: any) => {
		const { selectedItem } = stateAndItems;

		if (!selectedItem || selectedItem.name !== inputValue) {
			setQuery(inputValue);
		}

		if (inputValue === '' && selectedItem) {
			stateAndItems.clearSelection();
		}
	};

	return (
		<FieldWrapper>
			<Downshift
				onChange={onAcChange}
				onInputValueChange={onInputValueChange}
				itemToString={(item: acDataInterface) => (item ? item.name : '')}
			>
				{({
					getInputProps,
					getItemProps,
					getLabelProps,
					getMenuProps,
					isOpen,
					inputValue,
					highlightedIndex,
					selectedItem,
				}: any) => {
					return (
						<div>
							<Label {...getLabelProps()} htmlFor="organizationac">
								Var arbetar du?
							</Label>
							<Input
								{...getInputProps()}
								id="organizationac"
								ref={inputRef}
								placeholder="Namn på din organisation..."
							/>
							{isOpen && (
								<List {...getMenuProps()}>
									{filteredSuggestions.map(
										(item: acDataInterface, index: number) => (
											<ListItem
												{...getItemProps({
													key: item.name,
													index,
													item,
													selected: highlightedIndex === index,
												})}
											>
												{item.custom && item.name}
												{!item.custom && <strong>{item.name}</strong>}
											</ListItem>
										)
									)}
								</List>
							)}
						</div>
					);
				}}
			</Downshift>
			{loading && <InputLoader />}
			{error && 'Ett fel uppstod'}
		</FieldWrapper>
	);
};

export default AutoComplete;
