/**
 * For tracking when a DOM node has been visible (scrolled to).
 * Returns a ref for the node to be tracked.
 *
 * @returns {Array}
 */

import { useEffect, useRef } from 'react';
let reported = false;

export const useTrackScrollToNode = (reportMessage: string) => {
	const intersectTarget = useRef<any>(null);

	useEffect(() => {
		const opts = {
			root: null,
			rootMargin: '0px',
			threshold: 0,
		};
		const callback = (list: any) => {
			list.forEach((entry: any) => {
				if (entry.isIntersecting && !reported) {
					if (window.dataLayer) {
						window.dataLayer.push({
							event: 'eventTracking',
							category: 'Scroll',
							action: reportMessage,
							label: '',
						});
					}
					reported = true;
				}
			});
		};
		const observerScroll = new IntersectionObserver(callback, opts);

		const waitForDomNode = setInterval(() => {
			if (intersectTarget.current) {
				observerScroll.observe(intersectTarget.current);
				clearInterval(waitForDomNode);
			}
		}, 500);

		return () => {
			observerScroll.disconnect();
			if (waitForDomNode) {
				clearInterval(waitForDomNode);
			}
		};
	}, [reportMessage]);

	return [intersectTarget];
};
