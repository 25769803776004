/**
 * ChapterNavigation
 * @module components/ChapterNavigation
 */

import React from 'react';
import {
	Circle,
	CircleLink,
	StarIcon,
	CheckIcon,
	CircleIcon,
	HiddenHover,
	DisplayHover,
	StylePropsInterface,
} from './ChapterNavigation.styles';

interface PropsInterface extends StylePropsInterface {
	children?: any;
	icon?: 'star' | 'check' | 'circle';
	hoverContent?: number;
	label?: string;
	url?: string | null;
}

const ChapterNavigationIcon: React.FC<PropsInterface> = props => {
	const {
		children,
		icon,
		hoverContent,
		textcolor,
		backgroundcolor,
		label,
		url,
	} = props;

	const renderContent = () => {
		if (icon && icon === 'star') {
			return <StarIcon />;
		} else if (icon && icon === 'check') {
			return <CheckIcon />;
		} else if (icon && icon === 'circle') {
			return <CircleIcon />;
		} else {
			return children;
		}
	};

	if (url) {
		return (
			<CircleLink
				to={url || '#'}
				textcolor={textcolor}
				backgroundcolor={backgroundcolor}
				aria-label={label}
				data-gtm-category="Click"
				data-gtm-action="Chapter navigation"
				data-gtm-label={url || '#'}
			>
				<HiddenHover>{renderContent()}</HiddenHover>
				<DisplayHover>{hoverContent || renderContent()}</DisplayHover>
			</CircleLink>
		);
	} else {
		return (
			<Circle textcolor={textcolor} backgroundcolor={backgroundcolor}>
				<HiddenHover>{renderContent()}</HiddenHover>
				<DisplayHover>{hoverContent || renderContent()}</DisplayHover>
			</Circle>
		);
	}
};

export default ChapterNavigationIcon;
