/**
 * SurveyHeader
 * @module components/SurveyHeader
 */

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	HeaderWrapper,
	SkipToMainLink,
	Header,
	Title,
	Logo,
	LogoMobile,
	MobileStepCounter,
	MobileStepCounterText,
} from './SurveyHeader.styles';
import { ChapterInterface } from 'applications/survey/types';

interface Props {
	title?: string;
	chapters: ChapterInterface[];
	basePath: string;
	surveyId: number | string;
	startUrl: string;
}

const SurveyHeader: React.FC<Props> = props => {
	const { title, chapters, startUrl } = props;
	const { pathname } = useLocation();
	const activeChapterSegment = pathname.split('/')[2] || null;
	const stepCount = chapters.length + 1;
	let currentChapter = stepCount;
	const stepCounterInvisible =
		!activeChapterSegment || activeChapterSegment === 'resultat';

	if (activeChapterSegment && !stepCounterInvisible) {
		const activeChapter = chapters.filter((chapter: ChapterInterface) => {
			return chapter.segment === activeChapterSegment;
		});

		if (activeChapter[0]) {
			currentChapter = chapters.indexOf(activeChapter[0]) + 1;
		}
	}

	return (
		<HeaderWrapper>
			<SkipToMainLink href="#main-content">
				Hoppa till sidans huvudinnehåll
			</SkipToMainLink>
			<Header aria-label={title}>
				<Link to={startUrl} title="Gå till startsidan">
					<Logo />
					<LogoMobile />
				</Link>
				{title && <Title>{title}</Title>}
				<MobileStepCounter invisible={stepCounterInvisible}>
					<MobileStepCounterText>
						{currentChapter} av {stepCount}
					</MobileStepCounterText>
				</MobileStepCounter>
			</Header>
		</HeaderWrapper>
	);
};

export default SurveyHeader;
