/**
 * ReportPage
 * @module components/ReportPage
 */

import React, { useContext } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import { useAxios } from 'use-axios-client';
import { AxiosContext } from 'applications/survey/context/axios.context';
import { ThemeContext } from 'styled-components';
import {
	ReportSurveyInterface,
	ChapterInterface,
	RecommendationInterface,
	ReportQuestionInterface,
	ReportChapterInterface,
} from 'applications/survey/types';
import { LoaderFixed } from 'applications/survey/components/Loader';
import { SuveyHolder } from 'applications/survey/pages/SurveyPage/SurveyPage.styles';
import Meta from 'applications/survey/components/Meta';
import SurveyHeader from 'applications/survey/components/SurveyHeader';
import { Layout, Header } from 'applications/survey/components/Layout';
import Question from 'applications/survey/components/Question';
import { H1, H3, HtmlText } from 'applications/survey/components/Typography';
import { ChapterIcon } from 'applications/survey/pages/ChapterPage/ChapterPage.styles';
import { RecommendationWrapper } from './ReportPage.styles';
import { ReactComponent as StarIcon } from 'applications/survey/images/star.svg';

const ReportPage: React.FC = () => {
	const { surveyId } = useParams();
	const { path, url } = useRouteMatch();
	const { axiosInstance } = useContext(AxiosContext);
	const theme = useContext(ThemeContext);
	const { data, error, loading } = useAxios<ReportSurveyInterface>({
		axiosInstance,
		data: '',
		url: `/${surveyId}/report`,
	});

	const renderRecommendations = (
		recs: RecommendationInterface[],
		collapse: boolean
	) => {
		if (recs.length === 0) {
			return null;
		}

		return (
			<RecommendationWrapper collapse={collapse}>
				<H1>Tips</H1>
				{recs.map((rec: RecommendationInterface) => (
					<>
						<H3 as="h2">{rec.name}</H3>
						<HtmlText
							key={rec.name}
							size="small"
							font="alt"
							marginBottom={true}
							dangerouslySetInnerHTML={{
								__html: rec.text,
							}}
						/>
					</>
				))}
			</RecommendationWrapper>
		);
	};

	const renderChapter = (chapter: ReportChapterInterface) => {
		const {
			name,
			description,
			color,
			icon,
			questions,
			recommendations,
		} = chapter;

		return (
			<div key={chapter.id}>
				<Header marginTop={true}>
					<H1 withIcon={true}>
						{icon && color && (
							<ChapterIcon aria-hidden="true" backgroundColor={color}>
								<img src={icon} alt="" />
							</ChapterIcon>
						)}
						{name}
					</H1>
					{description && (
						<HtmlText dangerouslySetInnerHTML={{ __html: description }} />
					)}
				</Header>
				{recommendations && renderRecommendations(recommendations, false)}
				{questions &&
					questions.map((question: ReportQuestionInterface, index: number) => (
						<div key={index}>
							<Question
								{...question}
								index={index + 1}
								onChange={() => {}}
								showValidationError={false}
							/>
							{question.recommendations &&
								renderRecommendations(question.recommendations, true)}
						</div>
					))}
			</div>
		);
	};

	const renderReport = (data: ReportSurveyInterface) => {
		return (
			<SuveyHolder bgImg={true}>
				{data.meta && <Meta {...data.meta} />}
				<SurveyHeader
					title={data.applicationName || data.title}
					chapters={data.chapters}
					basePath={`${path}/:chapterId`}
					surveyId={surveyId || ''}
					startUrl={url}
				/>
				<Layout id="main-content" marginTop={true}>
					{data.chapters.map((chapter: ChapterInterface) =>
						renderChapter(chapter)
					)}
					{data.identityTitle && (
						<Header>
							<H1>{data.identityTitle}</H1>
							{data.identityDescription && (
								<HtmlText
									dangerouslySetInnerHTML={{
										__html: data.identityDescription,
									}}
								/>
							)}
						</Header>
					)}
					{
						<Header>
							<H1 withIcon={true}>
								<ChapterIcon
									aria-hidden="true"
									backgroundColor={theme.colors.green}
								>
									<StarIcon />
								</ChapterIcon>
								{data.resultTitle || ''}
							</H1>
							<HtmlText
								dangerouslySetInnerHTML={{
									__html: data.resultDescription || '',
								}}
							/>
						</Header>
					}
					{
						<Header>
							<H1 withIcon={true}>
								<ChapterIcon
									aria-hidden="true"
									backgroundColor={theme.colors.green}
								>
									<StarIcon />
								</ChapterIcon>
								{data.reVisitHeading || ''}
							</H1>
							<HtmlText
								dangerouslySetInnerHTML={{
									__html: data.reVisitDescription || '',
								}}
							/>
						</Header>
					}
				</Layout>
			</SuveyHolder>
		);
	};

	return (
		<>
			{loading && <LoaderFixed className="loader" />}
			{error && <div className="error">{error.message}</div>}
			{data && !loading && renderReport(data)}
		</>
	);
};

export default ReportPage;
