/**
 * SurveyPage
 * @module components/SurveyPage
 */

import React, { useContext, useState, useLayoutEffect, useEffect } from 'react';
import {
	Route,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory,
} from 'react-router-dom';
import { generatePath } from 'react-router';
import { useAxios } from 'use-axios-client';
import { useTrackPageTime } from 'applications/survey/hooks';
import { AxiosContext } from 'applications/survey/context/axios.context';
import { SurveyInterface, ChapterInterface } from 'applications/survey/types';
import { Layout } from 'applications/survey/components/Layout';
import Meta from 'applications/survey/components/Meta';
import { LoaderFixed } from 'applications/survey/components/Loader';
import ChapterPage from 'applications/survey/pages/ChapterPage';
import IdentificationPage from 'applications/survey/pages/IdentificationPage';
import ResultPage from 'applications/survey/pages/ResultPage';
import NoPageFound404 from 'applications/survey/pages/NoPageFound404';
import SurveyClosedPage from 'applications/survey/pages/SurveyClosedPage';
import SurveyHeader from 'applications/survey/components/SurveyHeader';
import { SuveyHolder } from './SurveyPage.styles';
import SurveyPageContent from './SurverPage.Content';
import ChapterNav from './SurveyPage.ChapterNavigation';

let lastTrackedPath: null | string = null;

const SurveyPage: React.FC = () => {
	const { pathname } = useLocation();
	const { surveyId } = useParams();
	const { path, url, isExact } = useRouteMatch();
	const { axiosInstance } = useContext(AxiosContext);
	const { data, error, loading } = useAxios<SurveyInterface>({
		axiosInstance,
		data: '',
		url: `/${surveyId}`,
	});
	const [survey, setSurvey] = useState<SurveyInterface>();
	// eslint-disable-next-line
	const [timeTracked, setTrackTime] = useTrackPageTime();
	const urlSegments = pathname.replace(/\/+$/, '').split('/');
	const lastUrlSegment = urlSegments[urlSegments.length - 1];
	const history = useHistory();
	const routes = {
		surveyPage: path,
		sharePage: `${path}/share/:shareId`,
		identificationPage: `${path}/identifiering`,
		resultPage: `${path}/resultat`,
		chapterPage: `${path}/:chapterId`,
	};

	const getChapterName = (pathname: string) => {
		if (survey) {
			const pathSegments = pathname.replace(/\/+$/, '').split('/');
			const lastPathSegment = pathSegments[pathSegments.length - 1];
			let chapter = survey.chapters.filter((chapter: ChapterInterface) => {
				return lastPathSegment === chapter.segment;
			})[0];

			if (chapter) {
				return chapter.name;
			} else {
				return lastPathSegment === surveyId ? 'startpage' : lastPathSegment;
			}
		}
		return null;
	};

	useLayoutEffect(() => {
		if (data !== null) {
			setSurvey(data);
		}
	}, [data]);

	useEffect(() => {
		if (survey && pathname !== lastTrackedPath) {
			lastTrackedPath = pathname;
			const chapterName = getChapterName(pathname);
			const pageTitle = chapterName
				? `${survey.applicationName} - ${getChapterName(pathname)}`
				: survey.applicationName;

			if (window.dataLayer && window.dataLayer.push) {
				window.dataLayer.push({
					event: 'Pageview',
					pagePath: pathname,
					pageTitle,
				});
			}
		}
		// eslint-disable-next-line
	}, [pathname, survey]);

	useEffect(() => {
		return history.listen(location => {
			setTrackTime(getChapterName(location.pathname));
		});
	});

	useEffect(() => {
		if (survey && survey.status !== 100) {
			setTrackTime(getChapterName(pathname));
		}
		// eslint-disable-next-line
	}, [survey]);

	const onChapterStatusChange = (chapterId: number, status: number) => {
		if (survey) {
			const updatedChapters = survey.chapters.map(chapter => {
				if (chapter.id === chapterId) {
					chapter.status = status;
				}
				return chapter;
			});
			setSurvey({ ...survey, chapters: updatedChapters });
		}
	};

	const isAllChaptersCompleted = (chapters: any) => {
		return chapters.every(({ status }: { status: number }) => status === 100);
	};

	const unfinishedChapters = (chapters: any) => {
		return chapters
			.map((chapter: ChapterInterface, index: number) => {
				return { ...chapter, index: index + 1 };
			})
			.filter(({ status }: { status: number }) => status !== 100);
	};

	const matchSurveyPage = useRouteMatch({
		path: routes.surveyPage,
		exact: true,
	});

	const matchShare = useRouteMatch({
		path: routes.sharePage,
		exact: true,
	});

	const matchIdentification =
		useRouteMatch({
			path: routes.identificationPage,
			exact: true,
		}) &&
		survey &&
		survey.status !== 100 &&
		isAllChaptersCompleted(survey.chapters);

	const matchResult =
		useRouteMatch({
			path: routes.resultPage,
			exact: true,
		}) &&
		survey &&
		survey.status === 100;

	const matchSurveyClosed = survey && survey.status === 100;

	const matchChapter =
		useRouteMatch({
			path: routes.chapterPage,
			exact: true,
		}) &&
		survey &&
		survey.status !== 100 &&
		lastUrlSegment !== 'resultat' &&
		lastUrlSegment !== 'identifiering';

	const renderRoutes = (survey: SurveyInterface) => {
		if (matchSurveyPage) {
			return <SurveyPageContent survey={survey} />;
		} else if (matchShare) {
			return (
				<Route path={routes.sharePage}>
					<ResultPage
						title={survey.applicationName}
						header={survey.resultTitle || ''}
						description={survey.resultDescription || ''}
						shareUrl={`${document.location.origin}${url}/share/${survey.dbGuid}`}
					/>
				</Route>
			);
		} else if (matchIdentification) {
			return (
				<Route path={routes.identificationPage}>
					<IdentificationPage
						header={survey.identityTitle || ''}
						title={survey.applicationName}
						description={survey.identityDescription || ''}
						dbId={survey.dbId}
						basePath={`${path}/:chapterId`}
						surveyId={survey.id}
						onSurveyComplete={() => {
							setSurvey({ ...survey, status: 100 });
						}}
					/>
				</Route>
			);
		} else if (matchResult) {
			return (
				<Route path={routes.resultPage}>
					<ResultPage
						title={survey.applicationName}
						header={survey.resultTitle || ''}
						description={survey.resultDescription || ''}
						dbId={survey.dbId}
						shareUrl={`${document.location.origin}${url}/share/${survey.dbGuid}`}
					/>
				</Route>
			);
		} else if (matchSurveyClosed) {
			return (
				<SurveyClosedPage
					title={survey.applicationName}
					resultPath={`${generatePath(path, {
						surveyId,
					})}/resultat`}
				/>
			);
		} else if (matchChapter) {
			return (
				<Route path={routes.chapterPage}>
					<ChapterPage
						title={survey.applicationName}
						dbId={survey.dbId}
						chapters={survey.chapters}
						onChapterStatusChange={onChapterStatusChange}
						allChaptersComplete={isAllChaptersCompleted(survey.chapters)}
						unfinishedChapters={unfinishedChapters(survey.chapters)}
					/>
				</Route>
			);
		} else {
			return <NoPageFound404 title={survey.applicationName} homeUrl={url} />;
		}
	};

	return (
		<>
			{loading && <LoaderFixed className="loader" />}
			{error && <div className="error">{error.message}</div>}
			{survey && !loading && (
				<SuveyHolder
					bgImg={
						isExact ||
						pathname.indexOf('/resultat') !== -1 ||
						pathname.indexOf('/share') !== -1
					}
				>
					{survey.meta && <Meta {...survey.meta} />}
					<SurveyHeader
						title={survey.applicationName || survey.title}
						chapters={survey.chapters}
						basePath={`${path}/:chapterId`}
						surveyId={surveyId || ''}
						startUrl={url}
					/>
					{!isExact && !matchShare && (
						<ChapterNav
							survey={survey}
							allChaptersComplete={isAllChaptersCompleted(survey.chapters)}
							isResultPage={matchResult}
						/>
					)}
					<Layout id="main-content" marginTop={true}>
						{renderRoutes(survey)}
					</Layout>
				</SuveyHolder>
			)}
		</>
	);
};

export default SurveyPage;
