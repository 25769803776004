/**
 * StandardLayout
 * @module layouts/StandardLayout
 */

import React from 'react';
import PropTypes from 'prop-types';

const StandardLayout = ({ children, header, footer }) => <>{children}</>;

StandardLayout.propTypes = {
	header: PropTypes.object,
	footer: PropTypes.object,
};

export default StandardLayout;
