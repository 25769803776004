import styled from 'styled-components';
import { ReactComponent as LogoImage } from 'applications/survey/images/logo.svg';
import { ReactComponent as LogoMobileImage } from 'applications/survey/images/logo-small.svg';
import { paragraphCss } from 'applications/survey/components/Typography';

interface StylePropsInterface {
	fixed?: boolean;
}

interface StepCounterPropsInterface {
	invisible?: boolean;
}

export const HeaderWrapper = styled.div<StylePropsInterface>`
	position: ${({ fixed }) => (fixed ? 'fixed' : 'relative')};
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
`;

export const SkipToMainLink = styled.a`
	${paragraphCss}
	display: block;
	text-align: center;
	background-color: ${({ theme }) => theme.colors.purple};
	color: ${({ theme }) => theme.colors.white};
	margin: 0;
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;

	&:active,
	&:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
`;

export const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.purple};
	padding: 2.1rem 3.2rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		padding: 1.6rem 2rem;
	}
`;

export const Logo = styled(LogoImage)`
	display: none;
	width: 12.3rem;
	height: 4.4rem;
	margin-top: -0.5rem;

	@media (min-width: ${({ theme }) => theme.mediaQueries.deviceMediumMin}) {
		display: block;
	}
`;

export const LogoMobile = styled(LogoMobileImage)`
	display: none;
	width: 2.7rem;
	height: 3.2rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		display: block;
	}
`;

export const Title = styled.h1`
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: 3.2rem;
	line-height: 4rem;
	font-weight: 300;
	color: ${({ theme }) => theme.colors.white};
	margin: 0 2rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 2rem;
		line-height: 2.4rem;
	}
`;

export const MobileStepCounter = styled.div<StepCounterPropsInterface>`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.white};
	visibility: hidden;
	min-width: 12.3rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		visibility: ${({ invisible }) => (invisible ? 'hidden' : 'visible')};
		min-width: 0;
	}
`;

export const MobileStepCounterText = styled.p`
	color: ${({ theme }) => theme.colors.whiteOpacity50};
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-weight: 300;
	margin: 0;
`;
