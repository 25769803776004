/**
 * SurveyPage - Chapter navigation
 * @module components/SurveyPage.ChapterNavigation
 */

import React from 'react';
import { generatePath } from 'react-router';
import { useRouteMatch, useLocation } from 'react-router-dom';
import {
	ChapterNavigation,
	ChapterNavigationIcon,
} from 'applications/survey/components/ChapterNavigation';
import { SurveyInterface, ChapterInterface } from 'applications/survey/types';
import { Layout } from 'applications/survey/components/Layout';
import { ChapterNavigationHolder } from './SurveyPage.styles';

interface Props {
	survey: SurveyInterface;
	allChaptersComplete: boolean;
	isResultPage?: boolean | null;
}

const ChapterNav: React.FC<Props> = props => {
	const { survey, allChaptersComplete, isResultPage } = props;
	const { pathname } = useLocation();
	const { path } = useRouteMatch();

	const getIdentificationUrl = () => {
		if (!survey) {
			return null;
		} else if (!allChaptersComplete) {
			return null;
		} else if (survey.status === 100) {
			return null;
		} else {
			return generatePath(`${path}/:chapterId`, {
				surveyId: survey.segment,
				chapterId: 'identifiering',
			});
		}
	};

	const identificationUrl = getIdentificationUrl();

	const renderChapterNavigationIcon = (
		chapter: ChapterInterface,
		index: number
	) => {
		const url =
			survey && survey.status === 100
				? null
				: generatePath(`${path}/:chapterId`, {
						surveyId: survey.segment,
						chapterId: chapter.segment,
				  });
		const activePath = url === pathname;
		const isNextChapter =
			survey.chapters[index - 1] && survey.chapters[index - 1].status === 100;

		if (activePath) {
			return (
				<ChapterNavigationIcon
					key={chapter.id}
					icon="circle"
					textcolor="green"
					hoverContent={index + 1}
					label={`Kapitel ${index + 1}`}
				/>
			);
		} else if (chapter.status === 100) {
			return (
				<ChapterNavigationIcon
					key={chapter.id}
					icon="check"
					hoverContent={index + 1}
					label={`Till kapitel ${index + 1}`}
					url={url}
				/>
			);
		} else if (isNextChapter) {
			return (
				<ChapterNavigationIcon
					key={chapter.id}
					label={`Till kapitel ${index + 1}`}
					url={url}
				>
					{index + 1}
				</ChapterNavigationIcon>
			);
		} else {
			return (
				<ChapterNavigationIcon
					key={chapter.id}
					label={`Till kapitel ${index + 1}`}
				>
					{index + 1}
				</ChapterNavigationIcon>
			);
		}
	};

	return (
		<ChapterNavigationHolder>
			<Layout>
				<ChapterNavigation>
					{survey.chapters.map(renderChapterNavigationIcon)}
					{identificationUrl === pathname && (
						<ChapterNavigationIcon
							label="Informationssida"
							icon="circle"
							textcolor="green"
						></ChapterNavigationIcon>
					)}
					{identificationUrl !== pathname && (
						<ChapterNavigationIcon
							label="Till informationssida"
							url={identificationUrl}
						>
							i
						</ChapterNavigationIcon>
					)}
					<ChapterNavigationIcon
						label="Resultatsidan"
						icon="star"
						textcolor={isResultPage ? 'green' : 'grey'}
					/>
				</ChapterNavigation>
			</Layout>
		</ChapterNavigationHolder>
	);
};

export default ChapterNav;
