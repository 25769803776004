import React from 'react';
import StartPage from './StartPage';
import SurveyContainerPage from './SurveyContainerPage';
import ErrorPage404 from './ErrorPage404';

const Pages = {
	StartPage,
	SurveyContainerPage,
	ErrorPage404,
	NotImplemented: () => (
		<p>The requested page type has not yet been implemented.</p>
	),
};

export const get = model => {
	if (model) {
		let page = Pages[model.modelType];

		if (page) {
			return page;
		}
	}

	return Pages['NotImplemented'];
};
