/**
 * NoPageFound404
 * @module components/NoPageFound404
 */

import React from 'react';
import Meta from 'applications/survey/components/Meta';
import { H1, Paragraph } from 'applications/survey/components/Typography';
import { Header } from 'applications/survey/components/Layout';
import { ButtonLink } from 'applications/survey/components/NavButtons/NavButtons.styles';

interface Props {
	title: string;
	homeUrl: string;
}

const NoPageFound404: React.FC<Props> = props => {
	const { title, homeUrl } = props;
	return (
		<Header>
			<Meta title={title}>
				<meta name="robots" content="noindex" />
			</Meta>
			<H1>404 - Sidan hittades inte</H1>
			<Paragraph>Sidan du vill nå kan inte hittas.</Paragraph>
			<br />
			<ButtonLink to={homeUrl}>Till startsidan</ButtonLink>
		</Header>
	);
};

export default NoPageFound404;
