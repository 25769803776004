import { useState } from 'react';

interface ISetCookieActionOptions {
	days?: number;
	path: string;
}

const setCookie = (
	name: string,
	value: string,
	options: ISetCookieActionOptions
) => {
	const expires = options.days
		? new Date(Date.now() + options.days * 864e5).toUTCString()
		: '';
	document.cookie =
		name +
		'=' +
		encodeURIComponent(value) +
		'; expires=' +
		expires +
		'; path=' +
		options.path;
};

const getCookie = (name: string): string => {
	return document.cookie.split('; ').reduce((r, v) => {
		const parts = v.split('=');
		return parts[0] === name ? decodeURIComponent(parts[1]) : r;
	}, '');
};

export const useCookie = (key: string, initialValue: string): [string, any] => {
	const [item, setItem] = useState(() => {
		return getCookie(key) || initialValue;
	});

	const updateItem = (
		value: string,
		options: ISetCookieActionOptions = { path: '/' }
	) => {
		setItem(value);
		setCookie(key, value, options);
	};

	return [item, updateItem];
};
