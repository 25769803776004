/**
 * OrganizationTypeSelect
 * @module components/OrganizationTypeSelect
 */

import React, { useState, useEffect } from 'react';
import { OrganizationTypeInterface } from 'applications/survey/types';
import { useOrgTypes } from 'applications/survey/api';
import {
	FieldWrapper,
	Select,
	Label,
	InputLoader,
} from './IdentificationForm.styles';

interface PropsInterface {
	onChange: any;
	selected?: string;
	disabled?: boolean;
}

const OrganizationTypeSelect: React.FC<PropsInterface> = props => {
	const [value, setValue] = useState('');
	const { onChange, selected } = props;
	const [data, error, loading] = useOrgTypes();

	const isDisabled = props.disabled || loading || error !== null;

	// Set selected
	useEffect(() => {
		if (selected) {
			setValue(selected);
		}
	}, [selected]);

	const handleChange = (e: any) => {
		const newValue = e.target.value;

		if (newValue !== '') {
			setValue(newValue);
			onChange(newValue);
		}
	};

	const renderOptions = (options: OrganizationTypeInterface[]) => {
		return (
			<>
				<option value="">Välj organisationstyp...</option>
				{options.map((item: OrganizationTypeInterface) => (
					<option value={item.typeId} key={item.typeId}>
						{item.typeName}
					</option>
				))}
			</>
		);
	};

	return (
		<FieldWrapper>
			<Label htmlFor="organizationtype">
				Vilken typ av organisation jobbar du på?
			</Label>
			<Select
				id="organizationtype"
				name="organizationtype"
				value={value}
				onChange={handleChange}
				disabled={isDisabled}
			>
				{loading && <option>Laddar...</option>}
				{error && <option>Ett fel uppstod...</option>}
				{data.length > 0 && renderOptions(data)}
			</Select>
			{loading && <InputLoader />}
			{error && 'Ett fel uppstod'}
		</FieldWrapper>
	);
};

export default OrganizationTypeSelect;
