import styled, { css } from 'styled-components';
import { animated } from 'react-spring/web.cjs';

export interface StylePropsInterface {
	variant?: 'grey';
	backgroundColor?: string;
	hidden?: boolean;
}

export const ProgressText = styled.p`
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-weight: 700;
	color: ${({ theme }) => theme.colors.black};
	font-size: 2rem;
	line-height: 1.4rem;
	margin: 0 0 0 5.8rem;
	padding: 0;
	min-width: 5rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 1.6rem;
		line-height: 1.4rem;
		min-width: 4rem;
		margin-left: 3rem;
	}
`;

export const ProgressTextAnimated = styled(animated.span)<StylePropsInterface>`
	display: ${({ hidden }) => (hidden ? 'none' : 'inline')};

	&:after {
		content: '%';
	}
`;

export const Bar = styled.div`
	height: 1.6rem;
	background-color: ${({ theme }) => theme.colors.greyLighter};
	border-radius: 1.6rem;
	overflow: hidden;
	margin-bottom: 0.8rem;
	flex: 1;
`;

export const Progress = styled.div<StylePropsInterface>`
	height: 100%;
	background-color: ${({ theme, backgroundColor }) =>
		backgroundColor ? backgroundColor : theme.colors.greyDark};
`;

export const NoProgressLabel = styled(ProgressText)`
	display: inline-block;
	color: ${({ theme }) => theme.colors.grey};
	font-weight: 300};
	margin: 0 0.5rem 0 0;
	min-width: 0;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		min-width: 0;
		margin-left: 0;
	}
`;

export const BarChartWrapper = styled.div<StylePropsInterface>`
	display: flex;
	align-items: baseline;

	${props =>
		props.variant === 'grey' &&
		css`
			${ProgressText} {
				color: ${({ theme }) => theme.colors.greyDark};
				font-weight: 300};
			}

			${NoProgressLabel} {
				color: ${({ theme }) => theme.colors.grey};
			}

			${Progress} {
				opacity: 0.3;
			}
		`}
`;
