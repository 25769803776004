/**
 * SurveyRouter
 * @module pages/SurveyRouter
 */

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider } from 'styled-components';
import Theme from 'applications/survey/theme';
import Survey from 'applications/survey/pages/SurveyPage';
import SurveyStartpage from 'applications/survey/pages/StartPage';
import Report from 'applications/survey/pages/ReportPage';
import 'applications/survey/css/editor.css';

const GlobalStyle = createGlobalStyle`
	*, *:before, *:after {
		box-sizing: inherit;
	}
	html {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		font-size: 62.5%;
		width:100vw;
    	overflow-x: hidden;
	}
	body {
		margin: 0;
		padding: 0;
		font-size: 2rem;
		font-family: 'sirba-web', sans-serif;
		background-color: #f7f7f7;
	}
`;

const SurveyRouter = ({ basePath }) => (
	<ThemeProvider theme={Theme}>
		<Router>
			<Switch>
				<Route path={`${basePath}`} exact>
					<SurveyStartpage />
				</Route>
				<Route path={`${basePath}:surveyId/kapitelutskrift`}>
					<Report />
				</Route>
				<Route path={`${basePath}:surveyId`}>
					<Survey />
				</Route>
			</Switch>
		</Router>
		<GlobalStyle />
	</ThemeProvider>
);

export default SurveyRouter;
