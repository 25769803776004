/**
 * ErrorPage404
 * @module pages/ErrorPage404
 */

import React from 'react';
import { renderElementBasedOnEditMode } from 'common/helpers';

const ErrorPage404 = ({ content }) => {
	const { heading, text, isInEditMode } = content;

	return (
		<main id="main-content" className="error-page">
			{renderElementBasedOnEditMode(isInEditMode, 'heading', 'h1', {}, heading)}
			{renderElementBasedOnEditMode(isInEditMode, 'text', 'div', {
				dangerouslySetInnerHTML: { __html: text },
			})}
		</main>
	);
};

export default ErrorPage404;
