import styled from 'styled-components';
import { Wrapper } from 'applications/survey/components/Layout';

interface StylePropsInterface {
	collapse: boolean;
}

export const RecommendationWrapper = styled(Wrapper)<StylePropsInterface>`
	margin-top: ${({ collapse }) => (collapse ? '-12rem' : '0')};

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		margin-top: ${({ collapse }) => (collapse ? '-5rem' : '0')};
	}
`;
