import React, { Component } from 'react';

export const LocalizationContext = React.createContext({});

export class LocalizationProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: props.model.translations || {},
		};
	}

	/**
	 * Translate a given term
	 */
	t = term => {
		return this.state.model[`/frontend/${term}`]
			? this.state.model[`/frontend/${term}`]
			: `/frontend/${term}`;
	};

	/**
	 * The render function
	 */
	render() {
		const { children } = this.props;

		return (
			<LocalizationContext.Provider
				value={{
					t: this.t,
				}}
			>
				{children}
			</LocalizationContext.Provider>
		);
	}
}

export const LocalizationConsumer = LocalizationContext.Consumer;
