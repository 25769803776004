import styled, { css } from 'styled-components';
import { Loader } from 'applications/survey/components/Loader';

interface ListItemProps {
	selected?: boolean;
}

const inputBaseStyle = css`
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: 2rem;
	line-height: 2.4rem;
	color: ${({ theme }) => theme.colors.greyDark};
	background-color: ${({ theme }) => theme.colors.greyLighter};
	border: none;
	width: 100%;
	padding: 1.2rem 1.2rem 1.2rem;
	margin-right: 1rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 1.6rem;
	}
`;

export const Form = styled.form`
	max-width: 38.6rem;
`;

export const FieldWrapper = styled.div`
	position: relative;
	margin-bottom: 3.2rem;
`;

export const Label = styled.label`
	display: block;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-weight: 700;
	font-size: 2rem;
	line-height: 3.2rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 1.6rem;
	}
`;

export const Input = styled.input`
	${inputBaseStyle}
`;

export const Select = styled.select`
	${inputBaseStyle}
	padding-right: 5.5rem;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 1.4rem top 50%;
	background-size: 1.3rem auto;

	&:disabled {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath style='fill: %23aaa' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
	}

	&::-ms-expand {
		display: none;
	}

	& option {
		color: ${({ theme }) => theme.colors.black};
	}
`;

export const InputLoader = styled(Loader)`
	position: absolute;
	right: 3.5rem;
	bottom: 1.3rem;
`;

export const List = styled.ul`
	position: absolute;
	top: 100%;
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;
	z-index: 300;
	background-color: ${({ theme }) => theme.colors.greyLighter};
`;

export const ListItem = styled.li<ListItemProps>`
	padding: 1rem 1.2rem;
	background-color: ${({ theme, selected }) =>
		selected ? theme.colors.greyLight : 'transparent'};
`;

export const Separator = styled.p`
	position: relative;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: 1.6rem;
	line-height: 1.8rem;
	border-bottom: 0.2rem solid ${({ theme }) => theme.colors.grey}
	margin: 4rem 0;
	color: ${({ theme }) => theme.colors.black};

	span {
		display: inline-block;
		background-color: ${({ theme }) => theme.colors.white};
		position: absolute;
		padding: 0 0.6rem;
		top: -0.8rem;
		left: 1.6rem;
	}
`;
