/**
 * IdentificationForm
 * @module components/IdentificationForm
 */

import React, { useState } from 'react';
import AutoComplete from './AutoComplete';
import { FaArrowRight } from 'react-icons/fa';
import OrganizationTypeSelect from './OrganizationTypeSelect';
import OrganizationSizeSelect from './OrganizationSizeSelect';
import { Wrapper } from 'applications/survey/components/Layout';
import { Form } from './IdentificationForm.styles';
import { Button } from 'applications/survey/components/NavButtons/NavButtons.styles';

interface Props {
	onSubmit: any;
}

const IdentificationForm: React.FC<Props> = props => {
	const { onSubmit } = props;
	const [acValue, setAcValue] = useState();
	const [orgTypeValue, setOrgTypeValue] = useState();
	const [orgSizeValue, setOrgSizeValue] = useState();
	const [showManualSelect, setShowManualSelect] = useState(false);

	const handleAcChange = (value: any) => {
		if (value.custom) {
			// Reset the selects if an AC-value was selected before
			if (!showManualSelect) {
				setOrgTypeValue(null);
				setOrgSizeValue(null);
			}
			setShowManualSelect(true);
			setAcValue(null);
		} else {
			setAcValue(value);
			setShowManualSelect(false);
			if (value !== null) {
				setOrgTypeValue(value.typeId);
				setOrgSizeValue(value.sizeClassId);
			}
		}
	};

	const handleOrgTypeChange = (value: any) => {
		setOrgTypeValue(value);
	};

	const handleOrgSizeChange = (value: any) => {
		setOrgSizeValue(value);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const data: any = {};
		data.organizationType = orgTypeValue;
		data.organizationSize = orgSizeValue;
		data.organizationName = acValue ? acValue.name : null;
		onSubmit(data);
	};

	const hasData = orgTypeValue !== undefined && orgSizeValue !== undefined;

	return (
		<Wrapper>
			<Form onSubmit={handleSubmit}>
				<AutoComplete onChange={handleAcChange} />
				{showManualSelect && (
					<>
						<OrganizationTypeSelect
							onChange={handleOrgTypeChange}
							selected={orgTypeValue}
							disabled={acValue && acValue.typeId}
						/>
						<OrganizationSizeSelect
							typeId={orgTypeValue}
							onChange={handleOrgSizeChange}
							selected={orgSizeValue}
							disabled={acValue && acValue.sizeClassId}
						/>
					</>
				)}

				<Button type="submit" disabled={!hasData}>
					Gå vidare <FaArrowRight />
				</Button>
			</Form>
		</Wrapper>
	);
};

export default IdentificationForm;
