/**
 * ResultPage
 * @module components/ResultPage
 */

import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { FaCaretUp } from 'react-icons/fa';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
	useCookie,
	useTrackScrollToNode,
	useExternalLinks,
} from 'applications/survey/hooks';
import Meta from 'applications/survey/components/Meta';
import { useOrgTypes, useOrgSize } from 'applications/survey/api';
import { AxiosContext } from 'applications/survey/context/axios.context';
import {
	ResultInterface,
	ChapterResultInterface,
	ChapterRecommendationInterface,
	ChapterRecommendationQuestionInterface,
	RecommendationInterface,
	OrganizationTypeInterface,
	OrganizationSizeInterface,
} from 'applications/survey/types';
import { LoaderFixed } from 'applications/survey/components/Loader';
import {
	H1,
	H2,
	H3,
	Paragraph,
	HtmlText,
} from 'applications/survey/components/Typography';
import { ChapterIcon } from 'applications/survey/pages/ChapterPage/ChapterPage.styles';
import { ReactComponent as StarIcon } from 'applications/survey/images/star.svg';
import { Wrapper, Header } from 'applications/survey/components/Layout';
import BarChart from 'applications/survey/components/BarChart';
import ShareLink from 'applications/survey/components/ShareLink';
import {
	ChapterResult,
	ChapterResultContent,
	ChapterResultIcon,
	CompareHeaderWrapper,
	DateText,
	RecommendationHeader,
	RecommendationScore,
	CompareSelectHeader,
	ScreenReaderLabel,
	Select,
	SelectSeperator,
	ToggleLinkText,
	ToggleLinkWrapper,
	TopTipIcon,
	ToTopLink,
} from './ResultPage.styles';

const CancelToken = axios.CancelToken;
let cancel: any;

interface Props {
	dbId?: number;
	header: string;
	description: string;
	title: string;
	shareUrl: string;
}

const ResultPage: React.FC<Props> = props => {
	const { shareId, surveyId } = useParams();
	const { dbId, header, description, title, shareUrl } = props;
	const theme = useContext(ThemeContext);
	const [compareOrgType, setCompareOrgType] = useCookie('compare-type', '');
	const [compareOrgSize, setCompareOrgSize] = useCookie('compare-size', '');
	const [trackScrollRef] = useTrackScrollToNode('All recommendations shown');
	const [externalLinkRef] = useExternalLinks();
	const [orgTypes] = useOrgTypes();
	const [orgSizes] = useOrgSize(compareOrgType);
	const [loading, setLoading] = useState(false);
	const [includeRecommendations, setIncludeRecommendations] = useState(true);
	const [error, setError] = useState<any>(false);
	const [data, setData] = useState();
	const [compareOrgTypeName, setCompareOrgTypeName] = useState('Alla');
	const [showAllRecommendations, setShowAllRecommendations] = useState(false);
	const { axiosInstance } = useContext(AxiosContext);
	const {
		tooltipNoTotalScore,
		tooltipNoAvgTotalScore,
		tooltipNoChapterScore,
		tooltipNoAvgChapterScore,
	} = data || false;

	useEffect(() => {
		let selectedOrgType = orgTypes.filter(
			(orgType: OrganizationTypeInterface) => {
				return orgType.typeId === compareOrgType;
			}
		)[0];

		if (selectedOrgType) {
			setCompareOrgTypeName(selectedOrgType.typeName);
		} else {
			setCompareOrgTypeName('Alla');
		}
	}, [orgTypes, compareOrgType]);

	useEffect(() => {
		// Cancel any previous request
		if (cancel) {
			cancel();
		}
		setLoading(true);
		setError(false);

		const headers: any = {};

		if (dbId) {
			headers.dbId = dbId;
		} else if (shareId) {
			headers.dbGuid = shareId;
		}

		axiosInstance
			.get(
				`/${surveyId}/result?includeRecommendations=${includeRecommendations}&organizationType=${compareOrgType}&organizationSize=${compareOrgSize}`,
				{
					headers,
					data: {},
					cancelToken: new CancelToken(function executor(c: any) {
						cancel = c;
					}),
				}
			)
			.then((response: any) => {
				setLoading(false);

				if (includeRecommendations) {
					setIncludeRecommendations(false);
					setData(response.data);
				} else {
					setData((oldData: any) => ({
						...oldData,
						chapters: response.data.chapters,
						avgScore: response.data.avgScore,
					}));
				}
			})
			.catch((error: any) => {
				if (axiosInstance.isCancel && !axiosInstance.isCancel(error)) {
					setError(error);
					setLoading(false);
				}
			});
		// eslint-disable-next-line
	}, [dbId, axiosInstance, compareOrgType, compareOrgSize, surveyId]);

	const hasNoValidScore = (score: any) => {
		return score === 'NaN' || score === 0;
	};

	const renderChapterResults = (
		chapter: ChapterResultInterface,
		index: number
	) => {
		const animationDelay = (index + 1) * 400;
		return (
			<ChapterResult key={chapter.name}>
				<ChapterResultIcon>
					{chapter.icon && chapter.color && (
						<ChapterIcon
							aria-hidden="true"
							backgroundColor={chapter.color}
							size="small"
						>
							<img src={chapter.icon} alt="" />
						</ChapterIcon>
					)}
				</ChapterResultIcon>

				<ChapterResultContent>
					<H3 size="small">{chapter.name}</H3>
					<BarChart
						progress={chapter.score}
						animationDelay={animationDelay}
						backgroundColor={chapter.color || undefined}
						tooltip={hasNoValidScore(chapter.score) && tooltipNoChapterScore}
					/>
					<BarChart
						progress={chapter.avgScore}
						animationDelay={animationDelay + 200}
						backgroundColor={chapter.color || undefined}
						tooltip={
							hasNoValidScore(chapter.avgScore) && tooltipNoAvgChapterScore
						}
						variant="grey"
					/>
				</ChapterResultContent>
			</ChapterResult>
		);
	};

	const renderChapterRecommendations = (
		chapter: ChapterRecommendationInterface
	) => {
		if (!showAllRecommendations && !chapter.hasTop) {
			return null;
		}
		return (
			<ChapterResult key={chapter.id} border={true}>
				<ChapterResultIcon lessMargin={true}>
					{chapter.icon && chapter.color && (
						<ChapterIcon
							aria-hidden="true"
							backgroundColor={chapter.color}
							size="small"
						>
							<img src={chapter.icon} alt="" />
						</ChapterIcon>
					)}
				</ChapterResultIcon>

				<ChapterResultContent>
					<RecommendationHeader>{chapter.name}</RecommendationHeader>
					{chapter.questions.map(
						(question: ChapterRecommendationQuestionInterface) => {
							if (!showAllRecommendations && !question.hasTop) {
								return null;
							}
							return (
								<div key={question.questionId}>
									<Paragraph size="small" weight="bold" font="alt">
										{question.hasTop && <TopTipIcon />}
										{question.questionText}
									</Paragraph>
									<RecommendationScore
										size="small"
										textcolor="greyDark"
										font="alt"
									>
										Ditt svar: {question.answerText}
									</RecommendationScore>
									{question.recommendations.map(
										(rec: RecommendationInterface) => {
											if (!showAllRecommendations && !rec.top) {
												return null;
											}
											return (
												<HtmlText
													key={rec.name}
													size="small"
													font="alt"
													dangerouslySetInnerHTML={{
														__html: rec.text,
													}}
												/>
											);
										}
									)}
								</div>
							);
						}
					)}
				</ChapterResultContent>
			</ChapterResult>
		);
	};

	const renderResult = (data: ResultInterface) => {
		const surveyDate = (shareId && data.date) || '';
		const compareWithHeader = data.compareWithHeader || 'Jämför med';
		const resultPerChapterHeader =
			data.resultPerChapterHeader || 'Resultat per kapitel';
		const tipHeader = data.tipHeader || 'Tips';
		const tipDescription =
			data.tipDescription ||
			'Vi har valt ut de fem viktigaste tipsen för din organisation att börja arbeta med baserat på dina svar.  Längre ner på sidan kan du välja att visa samtliga tips.';
		const resultHeader =
			shareId && data.reVisitHeading ? data.reVisitHeading : header;
		const resultDescription =
			shareId && data.reVisitDescription
				? data.reVisitDescription
				: description;

		return (
			<div ref={externalLinkRef}>
				<Meta title={title}>
					<meta name="robots" content="noindex" />
				</Meta>
				<Header>
					<H1 withIcon={true}>
						<ChapterIcon
							aria-hidden="true"
							backgroundColor={theme.colors.green}
						>
							<StarIcon />
						</ChapterIcon>
						{resultHeader}
					</H1>
					<HtmlText
						dangerouslySetInnerHTML={{
							__html: resultDescription,
						}}
					/>
					<ShareLink url={shareUrl} />
				</Header>
				<Wrapper printPageBreak={true}>
					<CompareHeaderWrapper>
						<H2>{compareWithHeader}</H2>
						{surveyDate && (
							<DateText>
								Enkät besvarades:
								<br />
								{surveyDate}
							</DateText>
						)}
					</CompareHeaderWrapper>
					<CompareSelectHeader size="small">
						<ScreenReaderLabel htmlFor="organizationsize">
							Välj typ av organisation
						</ScreenReaderLabel>
						<Select
							id="organizationsize"
							name="orgType"
							value={compareOrgType}
							onChange={(e: any) => {
								setCompareOrgType(e.target.value);
								setCompareOrgSize('');
							}}
						>
							<option value="">Alla</option>
							{orgTypes.map((orgType: OrganizationTypeInterface) => (
								<option key={orgType.typeId} value={orgType.typeId}>
									{orgType.typeName}
								</option>
							))}
						</Select>
						<SelectSeperator aria-hidden="true">med</SelectSeperator>
						<ScreenReaderLabel htmlFor="organizationtype">
							Välj storlek på organisation
						</ScreenReaderLabel>
						<Select
							id="organizationtype"
							name="orgSize"
							value={compareOrgSize}
							onChange={(e: any) => {
								setCompareOrgSize(e.target.value);
							}}
						>
							<option value="">Alla storlekar</option>
							{orgSizes.map((orgSize: OrganizationSizeInterface) => (
								<option key={orgSize.sizeClassId} value={orgSize.sizeClassId}>
									{orgSize.sizeClassName}
								</option>
							))}
						</Select>
					</CompareSelectHeader>
					<H3 size="small">Din organisation</H3>
					<BarChart
						progress={data.score}
						animationDelay={400}
						backgroundColor={theme.colors.green}
						tooltip={hasNoValidScore(data.score) && tooltipNoTotalScore}
					/>
					<H3 size="small">{compareOrgTypeName}</H3>
					<BarChart
						progress={data.avgScore}
						animationDelay={600}
						backgroundColor={theme.colors.green}
						tooltip={hasNoValidScore(data.avgScore) && tooltipNoAvgTotalScore}
						variant="grey"
					/>

					<H2>{resultPerChapterHeader}</H2>
					{data.chapters.map(renderChapterResults)}
				</Wrapper>
				<Wrapper paddingBottom="1rem" id="tips">
					<H2>{tipHeader}</H2>
					<Paragraph>{tipDescription}</Paragraph>
					<ToggleLinkWrapper>
						<ToggleLinkText
							aria-controls="recommendations"
							secondary={showAllRecommendations}
							onClick={() => {
								setShowAllRecommendations(false);
							}}
						>
							<TopTipIcon />
							Visa topp 5
						</ToggleLinkText>
						<ToggleLinkText
							aria-controls="recommendations"
							data-gtm-category="Click"
							data-gtm-action="Toggle content"
							data-gtm-label="Visa alla rekommendationer"
							secondary={!showAllRecommendations}
							onClick={() => {
								setShowAllRecommendations(true);
							}}
						>
							Visa alla
						</ToggleLinkText>
					</ToggleLinkWrapper>
					<div role="region" id="recommendations" aria-live="polite">
						{data.chapterRecommendations.map(renderChapterRecommendations)}
						<div
							ref={trackScrollRef}
							style={{
								display: showAllRecommendations ? 'block' : 'none',
							}}
						></div>
					</div>
					<ToTopLink href="#tips">
						<FaCaretUp aria-hidden="true" /> Till toppen av tipsen
					</ToTopLink>
				</Wrapper>
			</div>
		);
	};

	return (
		<>
			{loading && <LoaderFixed className="loader" />}
			{error && <div className="error">{error.message}</div>}
			{data && renderResult(data)}
		</>
	);
};

export default ResultPage;
