/**
 * ChapterPage
 * @module components/ChapterPage
 */

import React, { useContext, useState, useEffect, useLayoutEffect } from 'react';
import { generatePath } from 'react-router';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import { useAxios } from 'use-axios-client';
import { AxiosContext } from 'applications/survey/context/axios.context';
import Meta from 'applications/survey/components/Meta';
import Question from 'applications/survey/components/Question';
import { H1, HtmlText } from 'applications/survey/components/Typography';
import { LoaderFixed } from 'applications/survey/components/Loader';
import NavButtons from 'applications/survey/components/NavButtons';
import { ChapterInterface, QuestionInterface } from 'applications/survey/types';
import { Header } from 'applications/survey/components/Layout';
import {
	ChapterIcon,
	ValidationMessage,
	ValidationMessageHeader,
	ValidationMessageIcon,
	ValidationMessageText,
	ValidationMessageList,
	ValidationMessageListItem,
	HiddenButton,
} from './ChapterPage.styles';

interface Props {
	dbId: number;
	chapters?: ChapterInterface[];
	onChapterStatusChange: any;
	allChaptersComplete: boolean;
	unfinishedChapters: ChapterInterface[];
	title: string;
}

const ChapterPage: React.FC<Props> = props => {
	const {
		dbId,
		chapters,
		onChapterStatusChange,
		allChaptersComplete,
		unfinishedChapters,
		title,
	} = props;
	const { surveyId, chapterId } = useParams();
	const { path } = useRouteMatch();
	const { axiosInstance } = useContext(AxiosContext);
	const defaultAnswers: { [k: number]: number } = {};
	const [answers, setAnswers] = useState(defaultAnswers);
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
	const [navButtonUrls, setNavButtonUrls] = useState({
		previousUrl: null,
		nextUrl: null,
		nextChapter: null,
	});
	const [saving, setSaving] = useState(false);
	const [showChapterValidation, setShowChapterValidation] = useState(false);
	const { data, error, loading } = useAxios<ChapterInterface>({
		axiosInstance,
		data: '',
		url: `/${surveyId}/chapters/${chapterId}`,
		headers: { dbId },
	});
	const [chapter, setChapter] = useState<ChapterInterface>();

	useLayoutEffect(() => {
		if (data !== null) {
			setChapter(data);
			setShowChapterValidation(false);
		}
	}, [data]);

	// Set default values
	useEffect(() => {
		if (data && data.questions) {
			const defaultValues: { [k: number]: number } = {};

			data.questions.forEach((question: QuestionInterface) => {
				const { selected } = question;
				if (selected !== null) {
					defaultValues[question.id] = selected;
				}
			});

			setAnswers(defaultValues);
		}
	}, [data]);

	// Save answers
	useEffect(() => {
		if (hasUnsavedChanges) {
			setSaving(true);

			const newData: { [k: string]: any[] } = { answers: [] };
			const id = chapter ? chapter.id : chapterId;

			for (let key in answers) {
				if (answers.hasOwnProperty(key)) {
					const questionAnswer: { [k: string]: number } = {
						questionId: parseInt(key, 10),
						answerId: answers[key],
					};
					newData.answers.push(questionAnswer);
				}
			}
			axiosInstance
				.put(`/${surveyId}/chapters/${id}`, newData, {
					headers: { dbId },
				})
				.then(() => {
					setSaving(false);
					setHasUnsavedChanges(false);
				})
				.catch((error: any) => {
					alert(error);
					setSaving(false);
				});
		}
		// eslint-disable-next-line
	}, [answers, hasUnsavedChanges, dbId, surveyId, chapterId, axiosInstance]);

	// Get next/prev chapters
	useEffect(() => {
		if (chapters) {
			let previousUrl = null;
			let nextUrl = null;
			let nextChapter = null;

			chapters.forEach((item, index) => {
				if (item.segment === chapterId) {
					let prevChapter = chapters[index - 1];
					nextChapter = chapters[index + 1];

					if (prevChapter) {
						previousUrl = generatePath(path, {
							surveyId,
							chapterId: prevChapter.segment,
						});
					} else {
						previousUrl = generatePath(path.replace('/:chapterId', ''), {
							surveyId,
						});
					}

					if (!chapter || chapter.status !== 100) {
						nextUrl = null;
					} else if (nextChapter) {
						nextUrl = generatePath(path, {
							surveyId,
							chapterId: nextChapter.segment,
						});
					} else if (allChaptersComplete) {
						nextUrl = generatePath(
							path.replace(':chapterId', 'identifiering'),
							{
								surveyId,
							}
						);
					}
				}
			});

			setNavButtonUrls({ previousUrl, nextUrl, nextChapter });
		}
	}, [chapters, chapterId, path, surveyId, allChaptersComplete, chapter]);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
	};

	const onAnswerChange = (questionId: number, answerId: number) => {
		const answerValue: { [k: number]: number } = { [questionId]: answerId };
		const updatedAnswers = { ...answers, ...answerValue };
		setAnswers(updatedAnswers);
		setHasUnsavedChanges(true);

		if (
			chapter &&
			chapter.questions &&
			chapter.questions.length === Object.keys(updatedAnswers).length
		) {
			onChapterStatusChange(chapter.id, 100);
			setChapter({ ...chapter, status: 100 });
		} else if (chapter && chapter.status !== 10) {
			onChapterStatusChange(chapter.id, 10);
			setChapter({ ...chapter, status: 10 });
		}
	};

	const renderValidationMessage = () => {
		if (chapter && chapter.status !== 100 && showChapterValidation) {
			return (
				<ValidationMessage as="div">
					<ValidationMessageHeader as="p">
						Observera
						<ValidationMessageIcon aria-hidden="true" />
					</ValidationMessageHeader>
					<ValidationMessageText>Kapitlet är ej slutfört</ValidationMessageText>
				</ValidationMessage>
			);
		} else if (!navButtonUrls.nextChapter && showChapterValidation) {
			return (
				<ValidationMessage as="div">
					<ValidationMessageHeader as="p">
						Observera
						<ValidationMessageIcon aria-hidden="true" />
					</ValidationMessageHeader>
					<ValidationMessageText>
						Följande kapitel är ej slutförda:
					</ValidationMessageText>
					<ValidationMessageList>
						{unfinishedChapters.map((chapter: ChapterInterface) => (
							<ValidationMessageListItem key={chapter.id}>
								<Link
									to={generatePath(path, {
										surveyId,
										chapterId: chapter.segment,
									})}
								>
									{chapter.index}. {chapter.name}
								</Link>
							</ValidationMessageListItem>
						))}
					</ValidationMessageList>
				</ValidationMessage>
			);
		} else {
			return null;
		}
	};

	const renderChapter = (chapter: ChapterInterface) => {
		const { name, description, color, icon, questions } = chapter;

		return (
			<>
				<Meta title={`${title} - ${name}`}>
					<meta name="robots" content="noindex" />
				</Meta>
				<Header>
					<H1 withIcon={true}>
						{icon && color && (
							<ChapterIcon aria-hidden="true" backgroundColor={color}>
								<img src={icon} alt="" />
							</ChapterIcon>
						)}
						{name}
					</H1>
					{description && (
						<HtmlText dangerouslySetInnerHTML={{ __html: description }} />
					)}
				</Header>
				<form onSubmit={onSubmit}>
					{questions &&
						questions.map((question: QuestionInterface, index: number) => (
							<Question
								{...question}
								index={index + 1}
								key={question.id}
								onChange={onAnswerChange}
								showValidationError={showChapterValidation}
							/>
						))}
					{!navButtonUrls.nextUrl &&
						unfinishedChapters.length > 0 &&
						renderValidationMessage()}
					<NavButtons
						previousUrl={navButtonUrls.previousUrl}
						nextUrl={navButtonUrls.nextUrl}
						nextButtonOnClick={() => {
							!navButtonUrls.nextUrl && setShowChapterValidation(true);
						}}
					/>
					<HiddenButton type="submit" aria-hidden="true">
						Skicka
					</HiddenButton>
				</form>
				{saving && <LoaderFixed />}
			</>
		);
	};

	return (
		<>
			{loading && <LoaderFixed className="loader" />}
			{error && <div className="error">{error.message}</div>}
			{chapter && !loading && renderChapter(chapter)}
		</>
	);
};

export default ChapterPage;
