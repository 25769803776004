import styled from 'styled-components';
import Tooltip from 'applications/survey/components/Tooltip';

interface LabelProps {
	dontKnow?: boolean;
	checked?: boolean;
}

interface FieldsetProps {
	error?: boolean;
}

export const Fieldset = styled.fieldset<FieldsetProps>`
	border: ${({ theme, error }) =>
		error ? `1rem solid ${theme.colors.magenta}` : `1rem solid transparent`};
	margin: 0 0 2.4rem;
	min-width: 0;
	background-color: ${({ theme }) => theme.colors.white};
	padding: 3.8rem 7.2rem 4.6rem;
	text-align: center;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceMediumMin}) {
		padding: 3.8rem 5rem 4.6rem;
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		border-width: 0.5rem;

		padding: 2.7rem 1rem 2.2rem;
		margin-left: -1.5rem;
		margin-right: -1.5rem;
	}
`;

export const Legend = styled.legend`
	display: table;
	float: left;
	padding: 0;
	width: 100%;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: 2.4rem;
	margin-top: 0;
	line-height: 3.2rem;
	margin-bottom: 1rem;
	font-weight: 700;
	text-align: center;

	+ * {
		clear: both;
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
`;

export const TooltipStyled = styled(Tooltip)`
	margin-left: 1rem;
	line-height: 0;
	vertical-align: middle;
`;

export const QuestionText = styled.p`
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: 2.4rem;
	line-height: 3.2rem;
	max-width: 83rem;
	margin: 0 auto 4rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
`;

export const MinMaxText = styled.p`
	display: flex;
	justify-content: space-between;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: 2rem;
	margin-top: 0;
	line-height: 3.2rem;
	margin-bottom: 1rem;
	color: ${({ theme }) => theme.colors.greyDark};

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 1.6rem;
		line-height: 1.6rem;
	}
`;

export const RadioWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 83rem;
	margin: 0.7rem auto 4.8rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		margin-bottom: 1.8rem;
	}
`;

export const RadioHolder = styled.div<LabelProps>`
	position: relative;
	cursor: pointer;
	display: inline-block;
	width: ${props => (props.dontKnow ? '10rem' : '12.8rem')};
	height: ${props => (props.dontKnow ? '4.8rem' : '12.8rem')};

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceMediumMin}) {
		width: ${props => (props.dontKnow ? '10rem' : '10rem')};
		height: ${props => (props.dontKnow ? '4.8rem' : '10rem')};
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		width: ${props => (props.dontKnow ? '7.5rem' : '4.8rem')};
		height: ${props => (props.dontKnow ? '4rem' : '4.8rem')};
	}
`;

export const Radio = styled.input`
	opacity: 0;
	position: fixed;
	width: 0;
`;

export const Label = styled.label<LabelProps>`
	cursor: pointer;
	margin: 0;
	display: inline-block;
	width: 100%;
	height: 100%;
	border: 0.2rem solid ${({ theme }) => theme.colors.greyLight};
	border-radius: 0.2rem;
	background-color: ${({ theme }) => theme.colors.white};

	&:hover,
	${Radio}:hover {
		border: 0.4rem solid ${({ theme }) => theme.colors.blue};
	}

	${Radio}:focus + & {
		border: 0.4rem solid ${({ theme }) => theme.colors.blue};
	}

	${Radio}:checked + & {
		background-color: ${({ theme }) => theme.colors.green};
		border-color: ${({ theme }) => theme.colors.green};
	}
	${Radio}:checked:focus + &,
	${Radio}:checked + &:hover {
		border: 0.2rem solid ${({ theme }) => theme.colors.white};
		box-shadow: 0 0 0 0.4rem ${({ theme }) => theme.colors.blue};
		border-radius: 0.005rem;
		width: calc(100% - 0.8rem);
		height: calc(100% - 0.8rem);
		margin: 0.4rem;
	}
`;

export const LabelText = styled.span<LabelProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: ${props => (props.dontKnow ? '1.8rem' : '4.5rem')};
	line-height: ${props => (props.dontKnow ? '4.8rem' : '12.8rem')};
	color: ${({ theme, checked }) =>
		checked ? theme.colors.white : theme.colors.black};
	text-align: center;
	vertical-align: middle;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceMediumMin}) {
		line-height: ${props => (props.dontKnow ? '4.8rem' : '10rem')};
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: ${props => (props.dontKnow ? '1.8rem' : '2.4rem')};
		line-height: ${props => (props.dontKnow ? '4rem' : '4.8rem')};
	}
`;
