/**
 * SurveyPage - Content
 * @module components/SurveyPage.Content
 */

import React from 'react';
import { generatePath } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { useExternalLinks } from 'applications/survey/hooks';
import { Header, Wrapper } from 'applications/survey/components/Layout';
import { ButtonLink } from 'applications/survey/components/NavButtons/NavButtons.styles';
import { H1, HtmlText } from 'applications/survey/components/Typography';
import { SurveyInterface } from 'applications/survey/types';

interface Props {
	survey: SurveyInterface;
}

const SurveyPageContent: React.FC<Props> = props => {
	const { survey } = props;
	const [externalLinkRef] = useExternalLinks();
	const { path } = useRouteMatch();

	return (
		<>
			<Header>
				<H1>{survey.title}</H1>
				{survey.description && (
					<HtmlText dangerouslySetInnerHTML={{ __html: survey.description }} />
				)}
				<br />
				<ButtonLink
					to={`${generatePath(path, {
						surveyId: survey.segment,
					})}/${survey.chapters[0].segment}`}
					data-gtm-category="Click"
					data-gtm-action="Start button"
				>
					Starta mätningen
				</ButtonLink>
			</Header>
			<Wrapper ref={externalLinkRef}>
				{survey.purpose && (
					<HtmlText
						marginBottom={true}
						dangerouslySetInnerHTML={{ __html: survey.purpose }}
					/>
				)}
			</Wrapper>
			<Header marginTop={true} as="div">
				<ButtonLink
					to={`${generatePath(path, {
						surveyId: survey.segment,
					})}/${survey.chapters[0].segment}`}
					data-gtm-category="Click"
					data-gtm-action="Start button"
				>
					Starta mätningen
				</ButtonLink>
			</Header>
		</>
	);
};

export default SurveyPageContent;
