/**
 * NavButtons
 * @module components/NavButtons
 */

import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Wrapper, ButtonLink, ButtonTextLink } from './NavButtons.styles';

interface Props {
	previousUrl?: string | null;
	nextUrl?: string | null;
	nextButtonOnClick?: any;
}

const NavButtons: React.FC<Props> = props => {
	const { previousUrl, nextUrl, nextButtonOnClick } = props;

	return (
		<Wrapper>
			<ButtonTextLink
				to={previousUrl || '#'}
				className={!previousUrl ? 'disabled' : ''}
				data-gtm-category="Click"
				data-gtm-action="Chapter navigation button previous"
				data-gtm-label={previousUrl || '#'}
			>
				<FaArrowLeft /> Gå tillbaka
			</ButtonTextLink>
			<ButtonLink
				to={nextUrl || '#'}
				onClick={nextButtonOnClick}
				className={!nextUrl ? 'disabled' : ''}
				data-gtm-category="Click"
				data-gtm-action="Chapter navigation button next"
				data-gtm-label={nextUrl || '#'}
			>
				Gå vidare <FaArrowRight />
			</ButtonLink>
		</Wrapper>
	);
};

export default NavButtons;
