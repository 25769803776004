// eslint-disable-next-line
import React, { useState, useContext, useEffect, useRef } from 'react';
import { AxiosContext } from 'applications/survey/context/axios.context';
import axios from 'axios';

const CancelToken = axios.CancelToken;

interface FetchConfigInterface {
	url: string;
	responseDefault?: any;
	preventRequest?: boolean;
	headers?: any;
	disableCancel?: boolean;
}
interface FetchReturnInterface extends Array<any | boolean> {
	0: any;
	1: any;
	2: boolean;
}

export const useFetch = ({
	url,
	responseDefault = null,
	preventRequest = false,
	headers = {},
	disableCancel = false,
}: FetchConfigInterface): FetchReturnInterface => {
	const { axiosInstance } = useContext(AxiosContext);
	const [response, setResponse] = useState(responseDefault);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const cancel = useRef<any>();

	useEffect(() => {
		if (preventRequest) {
			return;
		}

		// Cancel any previous request
		if (cancel && cancel.current && !disableCancel) {
			cancel.current();
		}
		setLoading(true);
		axiosInstance
			.get(url, {
				cancelToken: new CancelToken(function executor(c: any) {
					cancel.current = c;
				}),
				headers,
			})
			.then(({ data }: { data: any }) => {
				setLoading(false);
				setResponse(data);
			})
			.catch((error: any) => {
				setLoading(false);
				if (axiosInstance.isCancel && !axiosInstance.isCancel(error)) {
					setError(error);
				}
			});

		// Cleanup
		return () => {
			if (cancel && cancel.current) {
				cancel.current();
			}
		};
		// eslint-disable-next-line
	}, [url]);
	return [response, error, loading];
};

export const useOrgTypes = (): FetchReturnInterface => {
	return useFetch({
		url: '/identity/organization/types/',
		responseDefault: [],
	});
};

export const useOrgSize = (typeId: string): FetchReturnInterface => {
	const [response, error, loading] = useFetch({
		url: `/identity/organization/sizes?typeId=${typeId}`,
		responseDefault: [],
		preventRequest: typeId === undefined || typeId === '',
	});

	if (typeId === '') {
		return [[], error, loading];
	}

	return [response, error, loading];
};

export const useOrgAutocomplete = (query: string): FetchReturnInterface => {
	const [response, error, loading] = useFetch({
		url: `/identity/organization/?query=${query}`,
		responseDefault: [],
		preventRequest: query === '',
	});

	if (query === '') {
		return [[], error, loading];
	}

	return [response, error, loading];
};
