export const mediaQueries = {
	// Large (desktop)
	deviceLargeMax: '1038px',
	deviceLargeMin: '1025px',

	// Medium (tablet)
	deviceMediumMax: '1024px',
	deviceMediumMin: '768px',

	// Small (phone)
	deviceSmallMax: '767px',
	deviceSmallMin: '0px',
};
