/**
 * Meta
 */

import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
	title?: string;
	description?: string;
	ogSiteName?: string;
	ogType?: string;
	ogTitle?: string;
	ogUrl?: string;
	ogDescription?: string;
	ogUpdatedTime?: string;
	ogImage?: string | string[];
	twitterCard?: string;
	twitterTitle?: string;
	twitterUrl?: string;
	twitterDescription?: string;
	twitterImage?: string;
}

const Meta: React.FC<Props> = props => {
	const {
		children,
		title,
		description,
		ogSiteName,
		ogType,
		ogTitle,
		ogUrl,
		ogDescription,
		ogUpdatedTime,
		ogImage,
		twitterCard,
		twitterTitle,
		twitterUrl,
		twitterDescription,
		twitterImage,
	} = props;

	const renderOgImage = (image: string | string[]) => {
		let ogImages: string[];

		if (typeof image === 'string') {
			ogImages = [image];
		} else {
			ogImages = image;
		}

		return ogImages.map((img: string) => {
			return <meta property="og:image" key={img} content={img} />;
		});
	};

	// prettier-ignore
	return (
		<Helmet>
			{title && <title>{title}</title>}
			{description && <meta name="description" content={description} />}
			{ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
			{ogType && <meta property="og:type" content={ogType} />}
			{(ogTitle || title) && <meta property="og:title" content={ogTitle || title} />}
			{ogUrl && <meta property="og:url" content={ogUrl} />}
			{(ogDescription || description) && <meta property="og:description" content={ogDescription || description} />}
			{ogUpdatedTime && <meta property="og:updated_time" content={ogUpdatedTime} />}
			{ogImage && renderOgImage(ogImage)}

			{twitterCard && <meta name="twitter:card" content={twitterCard} />}
			{twitterCard && twitterTitle && <meta name="twitter:title" content={twitterTitle} />}
			{twitterCard && twitterUrl && <meta name="twitter:url" content={twitterUrl} />}
			{twitterCard && (twitterDescription || description) && <meta name="twitter:description" content={twitterDescription || description} />}
			{twitterCard && twitterImage && <meta name="twitter:image" content={twitterImage} />}

			{children}
		</Helmet>
	);
};

export default Meta;
