import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { getPageData } from 'common/dataHandler';

const model = window.__model || {
	react: {
		containerId: 'root',
		clientOnly: true,
		renderWithMockupData: true,
	},
};

const render = model => {
	const element = document.getElementById(model.react.containerId);

	if (element) {
		if (model.react.clientOnly) {
			if (model.react.renderWithMockupData) {
				getPageData().then(data => {
					model = { ...model, ...data };
					ReactDOM.render(<App {...model} />, element);
				});
			} else {
				ReactDOM.render(<App {...model} />, element);
			}
		} else {
			ReactDOM.hydrate(<App {...model} />, element);
		}
	}
};

render(model);
