/**
 * ShareLink
 * @module components/ShareLink
 */

import React, { useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
	ShareLinkWrapper,
	Input,
	ReadspeakerLabel,
	CopiedText,
	ButtonText,
} from './ShareLink.styles';
import { Button } from 'applications/survey/components/NavButtons/NavButtons.styles';
import { FiCopy } from 'react-icons/fi';

interface Props {
	url: string;
}

const ShareLink: React.FC<Props> = props => {
	const { url } = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const [copied, setCopied] = useState(false);

	return (
		<ShareLinkWrapper>
			<ReadspeakerLabel htmlFor="copy-url">Delnings-url</ReadspeakerLabel>
			<Input
				ref={inputRef}
				id="copy-url"
				type="text"
				readOnly={true}
				value={url}
			/>
			<CopyToClipboard text={url} onCopy={() => setCopied(true)}>
				<Button
					data-gtm-category="Click"
					data-gtm-action="Copy share url"
					data-gtm-label={url}
				>
					<FiCopy aria-hidden="true" />
					<ButtonText>&nbsp;Kopiera</ButtonText>
				</Button>
			</CopyToClipboard>
			{copied && <CopiedText>Kopierad!</CopiedText>}
		</ShareLinkWrapper>
	);
};

export default ShareLink;
