import styled from 'styled-components';

export interface ButtonProps {
	size?: 'small' | 'default';
	color?: string;
}

export const TooltipWrapper = styled.span`
	display: inline-block;

	@media (min-width: ${({ theme }) => theme.mediaQueries.deviceMediumMin}) {
		position: relative;
	}
`;

export const Popout = styled.span`
	display: block;
	position: absolute;
	left: -16.35rem;
	top: 3.5rem;
	background-color: ${({ theme }) => theme.colors.black};
	width: 35rem;
	border-radius: 0.4rem;
	color: ${({ theme }) => theme.colors.white};
	font-size: 1.8rem;
	line-height: 2.4rem;
	padding: 1.6rem;
	pointer-events: none;
	z-index: 1000;

	&:after {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: transparent;
		border-bottom-color: ${({ theme }) => theme.colors.black};
		border-width: 0.8rem;
		margin-left: -0.8rem;
	}

	& p {
		margin: 0;
		padding: 0;
		line-height: inherit;
		font-size: inherit;
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		top: auto;
		left: 1.5rem;
		width: calc(100% - 3rem);
		font-size: 1.6rem;
		margin-top: 1rem;
	}
`;

export const Button = styled.button<ButtonProps>`
	border: 0;
	background-color: transparent;
	padding: 0;
	cursor: pointer;
	font-size: ${({ size }) => (size === 'small' ? '1.8rem' : '2.4rem')};
	color: ${({ theme, color }) => (color ? color : theme.colors.greyDark)};

	&:hover,
	&:focus {
		color: ${({ theme }) => theme.colors.blue};
	}

	& * {
		pointer-events: none;
	}
`;
