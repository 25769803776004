/**
 * OrganizationSizeSelect
 * @module components/OrganizationSizeSelect
 */

import React, { useState, useEffect } from 'react';
import { OrganizationSizeInterface } from 'applications/survey/types';
import { useOrgSize } from 'applications/survey/api';
import {
	FieldWrapper,
	Select,
	Label,
	InputLoader,
} from './IdentificationForm.styles';

interface PropsInterface {
	typeId: string;
	onChange: any;
	selected?: string;
	disabled?: boolean;
}

const OrganizationSizeSelect: React.FC<PropsInterface> = props => {
	const { onChange, selected, typeId } = props;
	const [value, setValue] = useState('');
	const [options, error, loading] = useOrgSize(typeId);
	const isDisabled = props.disabled || loading || options.length === 0;

	// Set selected
	useEffect(() => {
		if (selected) {
			setValue(selected);
		}
	}, [selected]);

	const handleChange = (e: any) => {
		const newValue = e.target.value;

		if (newValue !== '') {
			setValue(newValue);
			onChange(newValue);
		}
	};

	const renderOptions = (options: OrganizationSizeInterface[]) => {
		return (
			<>
				<option value="">Välj storlek...</option>
				{options.map((item: OrganizationSizeInterface) => (
					<option value={item.sizeClassId} key={item.sizeClassId}>
						{item.sizeClassName}
					</option>
				))}
			</>
		);
	};

	return (
		<FieldWrapper>
			<Label htmlFor="organizationsize">
				Vad är storleken av din organisation?
			</Label>
			<Select
				id="organizationsize"
				name="organizationsize"
				value={value}
				onChange={handleChange}
				disabled={isDisabled}
			>
				{options && renderOptions(options)}
			</Select>
			{loading && <InputLoader />}
			{error && 'Ett fel uppstod'}
		</FieldWrapper>
	);
};

export default OrganizationSizeSelect;
