import styled from 'styled-components';

export const ShareLinkWrapper = styled.div`
	display: flex;
	position: relative;
	max-width: 65rem;

	@media print {
		display: none;
	}
`;

export const Input = styled.input`
	flex-grow: 1;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: 1.8rem;
	line-height: 3.2rem;
	color: ${({ theme }) => theme.colors.greyDark};
	background-color: ${({ theme }) => theme.colors.white};
	border: 0.1rem solid ${({ theme }) => theme.colors.grey};
	border-right: none;
	border-radius: 0.2rem 0 0 0.2rem;
	padding: 0 1.5rem;

	& + button {
		margin-left: -0.2rem;
		white-space: nowrap;
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 1.6rem;
	}
`;

export const ReadspeakerLabel = styled.label`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;

export const CopiedText = styled.p`
	position: absolute;
	top: 3rem;
	right: 0;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: 1.8rem;
	line-height: 3.2rem;
	color: ${({ theme }) => theme.colors.green};
`;

export const ButtonText = styled.span`
	display: inline-block;
`;
