import styled, { css } from 'styled-components';
import { IoMdStar } from 'react-icons/io';
import { H3, Paragraph } from 'applications/survey/components/Typography';
import { Button } from 'applications/survey/components/NavButtons/NavButtons.styles';

interface ChapterResultIconProps {
	lessMargin?: boolean;
}

interface ChapterResultProps {
	border?: boolean;
}

interface ToggleLinkTextProps {
	secondary?: boolean;
}

export const ChapterResultContent = styled.div`
	flex: 1;
`;

export const ChapterResultIcon = styled.div<ChapterResultIconProps>`
	min-width: 8.2rem;
	margin-top: 1.6rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		min-width: 4rem;
		margin-top: ${({ lessMargin }) => (lessMargin ? '1.4rem' : '2.4rem')};
	}
`;

export const ChapterResult = styled.div<ChapterResultProps>`
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.5rem;

	@media print {
		display: block;

		${ChapterResultIcon} {
			display: none;
		}
	}

	${props =>
		props.border &&
		css`
			padding-top: 2rem;

			${ChapterResultContent} {
				border-top: 0.2rem solid ${props.theme.colors.greyLight};
				padding-top: 2rem;
			}

			${ChapterResultIcon} {
				margin-top: 3.6rem;
			}
		`}
`;

export const CompareHeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
`;

export const DateText = styled.p`
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: 1.8rem;
	line-height: 2.2rem;
	color: ${({ theme }) => theme.colors.greyDark};

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 1.6rem;
		line-height: 2rem;
	}
`;

export const CompareSelectHeader = styled(H3)`
	margin-top: -2.6rem;
	margin-bottom: 3rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		margin-top: 0;
		margin-bottom: 2rem;
	}
`;

export const RecommendationHeader = styled(H3)`
	margin-top: 2.6rem;
	margin-bottom: 3rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
`;

export const RecommendationScore = styled(Paragraph)`
	margin: -0.7rem 0;
`;

export const ScreenReaderLabel = styled.label`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;

export const Select = styled.select`
	display: inline;
	padding: 0.5rem 3rem 0.5rem 1.1rem;
	border: 0.2rem solid ${({ theme }) => theme.colors.greyLight};
	border-radius: 0.3rem;
	outline: 0rem;
	font-size: inherit;
	font-weight: inherit;
	color: ${({ theme }) => theme.colors.teal};
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 1rem top 50%;
	background-size: 1rem auto;
	max-width: 42%;
`;

export const SelectSeperator = styled.p`
	display: inline;
	margin: 0 0.8rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		margin: 0 0.5rem;
	}
`;

export const ToggleLinkText = styled(Button)<ToggleLinkTextProps>`
	border-radius: 0.3rem 0 0 0.3rem;
	margin-left: 0;
	margin-right: 0;

	${props =>
		props.secondary &&
		css`
			background-color: ${props.theme.colors.white};
			border-color: ${props.theme.colors.greyLight};
			color: ${props.theme.colors.greyDark};
		`}

	&:focus,
	&:hover {
		border-radius: 0.005rem 0 0 0.005rem;
	}

	& * {
		pointer-events: none;
	}

	& svg {
		margin: -0.5rem 0 -0.3rem;
	}
`;

export const ToggleLinkWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 3rem 0 2rem;

	${ToggleLinkText} + ${ToggleLinkText} {
		border-radius: 0 0.3rem 0.3rem 0;

		&:focus,
		&:hover {
			border-radius: 0 0.005rem 0.005rem 0;
		}
	}
`;

export const TopTipIcon = styled(IoMdStar)`
	position: relative;
	display: inline-block;
	font-size: 2rem;
	margin-top: -0.3rem;
	margin-right: 0.3rem;
	vertical-align: middle;
	color: ${({ theme }) => theme.colors.yellow};
`;

export const ToTopLink = styled.a`
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	display: inline-block;
	font-size: 1.8rem;
	line-height: 1.6rem;
	font-weight: 700;
	color: ${({ theme }) => theme.colors.teal};
	text-align: center;
	width: 100%;
	margin: 4.8rem auto 1.8rem;
	border: none;
	background: none;
	cursor: pointer;

	& svg {
		position: relative;
		margin-bottom: -0.3rem;
	}

	& * {
		pointer-events: none;
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 1.6rem;
		margin-top: 2rem;
		margin-bottom: 0;
	}
`;
