/**
 * Question
 * @module components/Question
 */

import React, { useState } from 'react';
import { QuestionInterface, AnswerInterface } from 'applications/survey/types';
import {
	Fieldset,
	Legend,
	TooltipStyled,
	QuestionText,
	MinMaxText,
	RadioWrapper,
	RadioHolder,
	Radio,
	Label,
	LabelText,
} from './Question.styles';

interface PropsInterface extends QuestionInterface {
	index: number;
	showValidationError: boolean;
	onChange: any;
}

const Question: React.FC<PropsInterface> = props => {
	const {
		index,
		name,
		question,
		readMore,
		minValueDescription,
		maxValueDescription,
		answers,
		allowDontKnow,
		dontKnow,
		onChange,
		showValidationError,
	} = props;
	const questionId = props.id;
	const selectedProp = props.selected;
	const [selected, setSelected] = useState(selectedProp);

	const renderAnswer = (answer: AnswerInterface, dontKnowAnswer?: boolean) => {
		const answerId = answer.id;
		const answerText = answer.answer;
		const checked = selected === answerId;

		return (
			<RadioHolder key={answerId} dontKnow={dontKnowAnswer}>
				<Radio
					type="radio"
					id={`question-${questionId}-answer-${answerId}`}
					checked={checked}
					name={`question-${questionId}`}
					value={answerId}
					data-gtm-category="Click"
					data-gtm-action={`Question: ${question.trim()}`}
					data-gtm-label={answerText}
					onChange={(e: any) => {
						setSelected(parseInt(e.target.value, 10));
						onChange(questionId, parseInt(e.target.value, 10));
					}}
				/>
				<Label htmlFor={`question-${questionId}-answer-${answerId}`}>
					<LabelText dontKnow={dontKnowAnswer} checked={checked}>
						{answerText}
					</LabelText>
				</Label>
			</RadioHolder>
		);
	};

	return (
		<Fieldset error={showValidationError && selected === null}>
			<Legend>
				{`${index}. ${name}`}
				{readMore && (
					<TooltipStyled
						title={`${index}. ${name}`}
						className="question__tooltip"
					>
						<span dangerouslySetInnerHTML={{ __html: readMore }} />
					</TooltipStyled>
				)}
			</Legend>

			<QuestionText>{question}</QuestionText>

			<MinMaxText>
				<span>{minValueDescription}</span>
				<span>{maxValueDescription}</span>
			</MinMaxText>

			<RadioWrapper>
				{answers.map((answer: AnswerInterface) => renderAnswer(answer))}
			</RadioWrapper>

			{allowDontKnow && renderAnswer(dontKnow, true)}
		</Fieldset>
	);
};

export default Question;
