/**
 * BarChart
 * @module components/BarChart
 */

import React, { useContext } from 'react';
import { useSpring } from 'react-spring/web.cjs';
import { animated } from 'react-spring/web.cjs';
import { ThemeContext } from 'styled-components';
import Tooltip from 'applications/survey/components/Tooltip';
import {
	StylePropsInterface,
	Bar,
	Progress,
	BarChartWrapper,
	NoProgressLabel,
	ProgressText,
	ProgressTextAnimated,
} from './BarChart.styles';

interface Props extends StylePropsInterface {
	progress: number | 'NaN';
	animationDelay?: number;
	tooltip?: string | false;
}

const AnimatedDiv: React.FC = (props: any) => {
	return (
		<animated.div className={props.className} style={props.style}>
			{props.children}
		</animated.div>
	);
};

const BarChart: React.FC<Props> = props => {
	const { animationDelay, progress, variant, backgroundColor, tooltip } = props;
	const theme = useContext(ThemeContext);
	const animProps = useSpring({
		from: { width: '0%' },
		to: { width: progress === 'NaN' ? '0%' : `${progress}%` },
		delay: animationDelay || 0,
	});
	const animTextProps = useSpring({
		number: progress === 'NaN' ? 0 : progress,
		from: { number: 0 },
		delay: animationDelay || 0,
	});
	const noProgressLabel = progress === 'NaN' || progress === 0 ? 'n/a' : false;

	return (
		<BarChartWrapper variant={variant}>
			<Bar>
				<Progress
					style={animProps}
					backgroundColor={backgroundColor}
					as={AnimatedDiv}
				/>
			</Bar>
			<ProgressText>
				{noProgressLabel && (
					<NoProgressLabel as="span">{noProgressLabel}</NoProgressLabel>
				)}
				<ProgressTextAnimated hidden={progress === 'NaN' || progress === 0}>
					{animTextProps.number.interpolate(num => Math.round(num))}
				</ProgressTextAnimated>
				{tooltip && (
					<Tooltip title="Hjälp" size="small" color={theme.colors.grey}>
						{tooltip}
					</Tooltip>
				)}
			</ProgressText>
		</BarChartWrapper>
	);
};

export default BarChart;
