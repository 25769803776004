/**
 * SurveyClosedPage
 * @module components/SurveyClosedPage
 */

import React from 'react';
import Meta from 'applications/survey/components/Meta';
import { Link } from 'react-router-dom';
import { H1, Paragraph } from 'applications/survey/components/Typography';
import { Header } from 'applications/survey/components/Layout';

interface Props {
	resultPath: string;
	title: string;
}

const SurveyClosedPage: React.FC<Props> = props => {
	const { resultPath, title } = props;

	return (
		<Header>
			<Meta title={title}>
				<meta name="robots" content="noindex" />
			</Meta>
			<H1>Du är klar med undersökningen</H1>
			<Paragraph>
				{resultPath && <Link to={resultPath}>Se ditt resultat här</Link>}
			</Paragraph>
		</Header>
	);
};

export default SurveyClosedPage;
