import styled, { css } from 'styled-components';
import externalIcon from 'applications/survey/images/external-link-alt.svg';
import playIcon from 'applications/survey/images/play-circle.svg';
import starIcon from 'applications/survey/images/star-circle.svg';

interface StylePropsInterface {
	size?: 'small';
	weight?: 'bold';
	textcolor?: 'greyDark';
	font?: 'alt';
	marginBottom?: boolean;
}
interface H1Props {
	withIcon?: boolean;
}

export const paragraphCss = css<StylePropsInterface>`
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-size: ${({ size }) => (size === 'small' ? '2rem' : '2.4rem')};
	line-height: ${({ size }) => (size === 'small' ? '2.4rem' : '3.2rem')};
	margin-bottom: 1.2rem;
	margin-top: 2rem;
	font-weight: ${({ weight }) => (weight === 'bold' ? '700' : '400')};
	color: ${({ textcolor, theme }) =>
		textcolor === 'greyDark' ? theme.colors.greyDark : theme.colors.black};

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-family: ${({ theme, font }) =>
			font === 'alt'
				? theme.typography.fontFamilyAlt
				: theme.typography.fontFamily};
		font-size: 1.6rem;
		line-height: 2.4rem;
	}

	@media print {
		page-break-inside: avoid;
	}
`;

export const H1 = styled.h1<H1Props>`
	display: ${({ withIcon }) => (withIcon ? 'flex' : 'block')};
	align-items: flex-start;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-weight: 400;
	font-size: 4.5rem;
	line-height: 4.8rem;
	margin-bottom: 2.8rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 3.2rem;
		line-height: 4rem;
		margin-bottom: 1.3rem;
	}

	@media print {
		display: block;
	}
`;

export const H2 = styled.h2`
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-weight: 400;
	font-size: 3.2rem;
	line-height: 4rem;
	margin-bottom: 3.8rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: 2.4rem;
		line-height: 3.2rem;
		margin-bottom: 1.6rem;
	}

	@media print {
		page-break-after: avoid;
	}
`;

export const H3 = styled.h3<StylePropsInterface>`
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
	font-weight: 700;
	font-size: ${({ size }) => (size === 'small' ? '2rem' : '2.4rem')};
	line-height: ${({ size }) => (size === 'small' ? '2.4rem' : '3.2rem')};
	margin: 0 0 1rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		font-size: ${({ size }) => (size === 'small' ? '1.6rem' : '2rem')};
		line-height: 2.4rem;
	}
`;

export const Paragraph = styled.p<StylePropsInterface>`
	${paragraphCss}
`;

export const ScreenReaderText = styled.span`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;

export const HtmlText = styled.div<StylePropsInterface>`
	margin-bottom: ${({ marginBottom }) => (marginBottom ? '5.2rem' : '0')};

	img {
		display: block;
		max-width: 100%;
		height: auto;
		margin-bottom: 2rem;
	}

	p,
	h2,
	ul,
	ol {
		${paragraphCss}
	}

	p + h2,
	ul + h2,
	ol + h2 {
		margin-top: 2.5rem;
	}

	h2 {
		font-weight: 700;
		margin-bottom: 0;

		& + p {
			margin-top: 0.5rem;
		}
		& + ul,
		& + ol {
			margin-top: 1rem;
		}
	}

	a {
		color: ${({ theme }) => theme.colors.teal};

		&:not([href*='upphandlingsmyndigheten\.se']):not([href^='/']):not([href^='mailto:']):not([href^='tel:']):after {
			content: url(${externalIcon});
			display: inline-block;
			width: 1.6rem;
			height: 2.4rem;
			margin-left: 0.6rem;
		}

		&.icon-video:before,
		&.icon-example:before {
			display: inline-block;
			vertical-align: middle;
			margin-right: 0.6rem;
		}

		&.icon-video:before {
			content: url(${playIcon});
			width: 1.6rem;
		}

		&.icon-example:before {
			content: url(${starIcon});
			width: 1.8rem;
		}
	}
`;
