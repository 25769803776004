/**
 * For tracking/measure time spent on a page.
 * Returns the timer-object, a trackTime function and a summary function.
 *
 * @returns {Array}
 */

import { useEffect, useRef } from 'react';

export const useExternalLinks = () => {
	const wrapperRef = useRef<any>(null);

	useEffect(() => {
		if (wrapperRef.current) {
			const links = wrapperRef.current.querySelectorAll('a');

			links.forEach((link: any) => {
				if (
					link.getAttribute('target') === null &&
					(!window || link.hostname !== window.location.hostname)
				) {
					link.setAttribute('target', '_blank');
					link.setAttribute('rel', 'noopener noreferrer');
				}
			});
		}
	});

	return [wrapperRef];
};
