import styled from 'styled-components';
import { FaExclamationTriangle } from 'react-icons/fa';
import { paragraphCss } from 'applications/survey/components/Typography';
import {
	Fieldset,
	Legend,
	QuestionText,
} from 'applications/survey/components/Question/Question.styles';

interface ChapterIconProps {
	backgroundColor?: string;
	size?: string;
}

const iconSizes: any = {
	desktop: {
		standard: '6.4rem',
		small: '6.4rem',
		smaller: '4.8rem',
	},
	mobile: {
		standard: '4rem',
		small: '3.2rem',
		smaller: '3.2rem',
	},
};

export const Wrapper = styled.div`
	margin-bottom: 5rem;
`;

export const HiddenButton = styled.button`
	display: none;
`;

export const ChapterIcon = styled.span<ChapterIconProps>`
	display: inline-block;
	flex-shrink: 0;
	background-color: ${props =>
		props.backgroundColor ? props.backgroundColor : '#6B2879'};
	width: ${({ size = 'standard' }) => iconSizes.desktop[size]};
	height: ${({ size = 'standard' }) => iconSizes.desktop[size]};
	border-radius: 50%;
	margin: -0.8rem 1.8rem 0 0;

	& img,
	& svg {
		display: block;
		width: 100%;
		height: auto;
	}

	& + h1 {
		overflow: hidden;
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		width: ${({ size = 'standard' }) => iconSizes.mobile[size]};
		height: ${({ size = 'standard' }) => iconSizes.mobile[size]};
		margin-right: 0.8rem;
		margin-top: 0;
	}
`;

export const ValidationMessage = styled(Fieldset)`
	background-color: transparent;
`;

export const ValidationMessageIcon = styled(FaExclamationTriangle)`
	margin-left: 1rem;
	margin-bottom: -0.1rem;
	line-height: 0;
	vertical-align: baseline;
	color: ${({ theme }) => theme.colors.magenta};
`;

export const ValidationMessageHeader = styled(Legend)``;

export const ValidationMessageText = styled(QuestionText)`
	margin-bottom: 1rem;
`;

export const ValidationMessageList = styled.ul`
	${paragraphCss}
	list-style: none;
	padding: 0;

	a {
		color: ${({ theme }) => theme.colors.teal};
	}
`;

export const ValidationMessageListItem = styled.li`
	margin-bottom: 0.5rem;
`;
