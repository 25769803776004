import styled, { css } from 'styled-components';
import { FaCheck } from 'react-icons/fa';
import { IoMdStar } from 'react-icons/io';
import { Link } from 'react-router-dom';

export interface StylePropsInterface {
	textcolor?: 'grey' | 'green';
	backgroundcolor?: 'green' | 'undefined';
	hovercolor?: 'blue';
}

export const circleCss = css<StylePropsInterface>`
	display: inline-block;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlt};
    position: relative;
	width: 3.2rem;
	height: 3.2rem;
	border-radius: 50%;
	border: 0.4rem solid;
	border-color: ${({ theme, textcolor }) =>
		textcolor === 'green' ? theme.colors.green : theme.colors.grey};
	color: ${({ theme, textcolor, backgroundcolor }) => {
		if (textcolor === 'green' && backgroundcolor === 'green') {
			return theme.colors.white;
		} else if (textcolor === 'green') {
			return theme.colors.green;
		} else {
			return theme.colors.grey;
		}
	}}
    text-decoration: none;
	font-weight: bold;
    font-size: 1.6rem;
    background-color: ${({ theme, backgroundcolor }) =>
			backgroundcolor === 'green' ? theme.colors.green : theme.colors.white};

	& * {
		pointer-events: none;
	}
	`;

export const CircleLink = styled(Link)<StylePropsInterface>`
	${circleCss}

	&:hover, &:focus {
		background-color: ${({ theme }) => theme.colors.blue};
		border-color: ${({ theme }) => theme.colors.blue};
		color: ${({ theme }) => theme.colors.white};
	}
`;

export const Circle = styled.div<StylePropsInterface>`
	${circleCss}
`;

export const StarIcon = styled(IoMdStar)`
	position: relative;
	font-size: 2rem;
	margin-top: -0.3rem;
	vertical-align: middle;
`;

export const CheckIcon = styled(FaCheck)`
	position: relative;
	font-size: 1.8rem;
	vertical-align: middle;

	${Circle}:hover &,
	${CircleLink}:hover &,
	${Circle}:focus &,
	${CircleLink}:focus & {
		display: none;
	}
`;

export const CircleIcon = styled.div`
	background-color: ${({ theme }) => theme.colors.green};
	border-radius: 50%;
	width: calc(100% - 0.8rem);
	height: calc(100% - 0.8rem);
`;

export const HiddenHover = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	${Circle}:hover &,
	${CircleLink}:hover &,
	${Circle}:focus &,
	${CircleLink}:focus & {
		display: none;
	}
`;

export const DisplayHover = styled.div`
	position: relative;
	display: none;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;

	${Circle}:hover &,
	${CircleLink}:hover &,
	${Circle}:focus &,
	${CircleLink}:focus & {
		display: flex;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Line = styled.div`
	position: relative;
	&::before {
		display: inline-block;
		position: absolute;
		content: '';
		height: 0.4rem;
		width: 100%;
		background-color: ${({ theme }) => theme.colors.grey};
		top: 1.3rem;
	}

	@media print {
		display: none;
	}
`;
