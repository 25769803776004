import React from 'react';

/**
 * Renders an element based on whether or not we're in EPI's edit mode:
 * -If we're in edit mode, add data-epi-property-name attribute to element and always render
 * -If we're on site, render element if we have content, return empty string if no content
 *
 * @param {Boolean} isInEditMode - True if in Epi's edit mode, false if on site
 * @param {String} name - The name of the field in EPI
 * @param {String} Tag - The tag name, i.e. 'p', 'div' etc...
 * @param {Object} props - The props as an object to add to the HTML element, ie. className, id etc.
 * @param {String} content - The innerHTML of the tag
 */
export const renderElementBasedOnEditMode = (
	isInEditMode,
	name,
	Tag,
	props,
	content
) => {
	if (isInEditMode) {
		return (
			<Tag {...props} data-epi-property-name={name}>
				{content}
			</Tag>
		);
	} else {
		return content || props.dangerouslySetInnerHTML ? (
			<Tag {...props}>{content}</Tag>
		) : (
			''
		);
	}
};

/**
 * Sets a cookie.
 * @param {String} cname - The name of the cookie.
 * @param {String} cvalue - The value of the cookie.
 * @param {Number|Null} exdays - The number of days until the cookie should expire (can be null).
 * @param {String|Null} path - The path to set the cookie to (can be null).
 * @public
 */
export const setCookie = (cname, cvalue, exdays, path) => {
	let d = new Date(),
		cookieContent = cname + '=' + cvalue,
		expires;

	if (exdays !== null && exdays !== undefined) {
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		expires = '; expires=' + d.toUTCString();
		cookieContent += expires;
	}

	if (path !== null && path !== undefined) {
		cookieContent += '; path=' + path;
	}
	document.cookie = cookieContent;
};

/**
 * Gets a cookie.
 * @param {String} cname - The name of the cookie.
 * @public
 */
export const getCookie = cname => {
	let name = cname + '=',
		ca = document.cookie.split(';');

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}

	return '';
};

/**
 * Polyfill for forEach functions on objects to work on IE/Edge
 */
export const polyfillForEach = () => {
	if (
		typeof NodeList !== 'undefined' &&
		typeof NodeList.prototype.forEach !== 'function'
	) {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}
};
