/**
 * Tooltip
 * @module components/Tooltip
 * @see http://w3c.github.io/aria-practices/#tooltip
 * @see https://github.com/w3c/aria-practices/issues/127
 * @see https://inclusive-components.design/tooltips-toggletips/
 */

import React, { useRef, useState, useEffect } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { ButtonProps, TooltipWrapper, Popout, Button } from './Tooltip.styles';

interface Props extends ButtonProps {
	title: string;
	className?: string;
	children: any;
}

let buttonRefRef: any;

const Tooltip: React.FC<Props> = props => {
	const { title, className, size, color, children } = props;
	const buttonRef = useRef<HTMLButtonElement>(null);
	const [expanded, setExpanded] = useState(false);

	const handleClickOutside = (event: any) => {
		if (buttonRef.current && !buttonRef.current.contains(event.target)) {
			setExpanded(false);
		}
	};
	const handleKeyPress = (event: any) => {
		if ((event.keyCode || event.which) === 27) {
			setExpanded(false);
		}
	};
	const handleBlur = (event: any) => {
		setExpanded(false);
	};
	const handleTouchStart = (event: any) => {
		document.addEventListener('touchend', handleTouchEnd, false);
	};
	const handleTouchEnd = (event: any) => {
		document.removeEventListener('touchend', handleTouchEnd, false);
		setExpanded(false);
	};
	const handleTouchMove = (event: any) => {
		document.removeEventListener('touchend', handleTouchEnd, false);
	};

	useEffect(() => {
		if (buttonRef.current) {
			buttonRefRef = buttonRef.current;
			buttonRefRef.addEventListener('blur', handleBlur);
		}

		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleKeyPress);
		document.addEventListener('touchstart', handleTouchStart, false);
		document.addEventListener('touchmove', handleTouchMove, false);

		return () => {
			if (buttonRefRef && buttonRefRef.removeEventListener) {
				buttonRefRef.removeEventListener('blur', handleBlur);
			}
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleKeyPress);
			document.removeEventListener('blur', handleBlur);
			document.removeEventListener('touchstart', handleTouchStart, false);
			document.removeEventListener('touchmove', handleTouchMove, false);
		};
	});

	const openTooltip = () => {
		if (expanded) {
			setExpanded(false);
		}

		window.setTimeout(() => {
			setExpanded(true);
		}, 100);
	};

	return (
		<TooltipWrapper className={className}>
			<Button
				type="button"
				aria-label="Mer information"
				onClick={openTooltip}
				ref={buttonRef}
				size={size}
				color={color}
				data-gtm-category="Click"
				data-gtm-action="Tooltip button"
				data-gtm-label={title}
			>
				<FaQuestionCircle aria-hidden="true" />
			</Button>

			{expanded && <Popout role="status">{children}</Popout>}
		</TooltipWrapper>
	);
};

export default Tooltip;
