import styled from 'styled-components';
import { FaSpinner } from 'react-icons/fa';

export const Loader = styled(FaSpinner)`
	animation: rotation 2s infinite linear;
	color: ${({ theme }) => theme.colors.greyDark};

	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(359deg);
		}
	}
`;

export const LoaderFixed = styled(Loader)`
	position: fixed;
	bottom: 1rem;
	right: 1rem;
`;
