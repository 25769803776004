/**
 * StartPage
 * @module components/StartPage
 */

import React from 'react';
// import { useRouteMatch, useHistory } from 'react-router-dom';

const StartPage: React.FC = props => {
	return null;
	// const match = useRouteMatch();
	// const history = useHistory();
	// const [surveyId, setSurveyId] = useState('');

	// const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
	// 	e.preventDefault();
	// 	history.push(`${match.url}/${surveyId}`);
	// };

	// return (
	// 	<div className="survey-startpage">
	// 		<h1>Ange ett Survey id:</h1>
	// 		<form method="get" onSubmit={onSubmit}>
	// 			<input
	// 				type="text"
	// 				value={surveyId}
	// 				aria-label="Ange ett survey id"
	// 				onChange={(e: any) => setSurveyId(e.target.value)}
	// 			/>
	// 		</form>
	// 	</div>
	// );
};

export default StartPage;
