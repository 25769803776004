import styled from 'styled-components';
import backgroundImage from 'applications/survey/images/background.png';

interface StylePropsInterface {
	bgImg?: boolean;
}

export const SuveyHolder = styled.div<StylePropsInterface>`
	background: ${({ bgImg }) =>
		bgImg ? `url(${backgroundImage}) no-repeat center top` : 'none'};
	background-size: 100%;
	background-attachment: fixed;
	min-height: 100vh;
	padding-bottom: 3rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		padding-bottom: 6rem;
	}
`;

export const ChapterNavigationHolder = styled.div`
	background-color: #fff;
	padding: 3.2rem 0;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		display: none;
	}
`;
