import React from 'react';
import * as Pages from './pages';
import { polyfillForEach } from 'common/helpers';

import StandardLayout from 'layouts/StandardLayout';
import { LocalizationProvider } from 'context/localization.context';

polyfillForEach();

const App = props => {
	const { content, footer, header } = props;
	let Page = Pages.get(content);

	return (
		<LocalizationProvider model={props.localization}>
			<StandardLayout header={header} footer={footer}>
				<Page {...props} />
			</StandardLayout>
		</LocalizationProvider>
	);
};

export default App;
