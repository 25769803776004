import styled, { css } from 'styled-components';

interface StylePropsInterface {
	paddingBottom?: string;
	printPageBreak?: boolean;
	marginTop?: boolean;
}

export const Layout = styled.div<StylePropsInterface>`
	max-width: 96rem;
	margin: ${({ marginTop }) => (marginTop ? '6.4rem auto 0' : '0 auto')};

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceLargeMax}) {
		margin: 0 3.2rem;
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		margin: 0 1.5rem;
	}
`;

export const Wrapper = styled.div<StylePropsInterface>`
	margin: 0 0 2.4rem;
	background-color: ${({ theme }) => theme.colors.white};
	padding: 4.8rem 8.2rem 5.6rem;
	padding-bottom: ${({ paddingBottom }) =>
		paddingBottom ? paddingBottom : '5.6rem'};

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceMediumMin}) {
		padding-left: 6rem;
		padding-right: 6rem;
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		padding: 1.2rem 1.5rem 2.7rem;
		margin-left: -1.5rem;
		margin-right: -1.5rem;
	}

	@media print {
		page-break-inside: avoid;

		${props =>
			props.printPageBreak &&
			css`
				page-break-after: always;
			`}
	}
`;

export const Header = styled.header<StylePropsInterface>`
	max-width: 85rem;
	margin: ${({ marginTop }) => (marginTop ? '5rem auto 6rem' : '0 auto 6rem')};

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceLargeMax}) {
		margin: 0 6rem 6rem;
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		margin: 0 0 3.3rem;
	}

	@media print {
		margin-left: 8.2rem;
		margin-right: 8.2rem;
	}
`;
