/**
 * SurveyContainerPage
 * @module pages/SurveyContainerPage
 */

import React from 'react';
import axios from 'axios';
import SurveyRouter from 'applications/survey';
import { AxiosContext } from 'applications/survey/context/axios.context';

const surveyUrl = '/'; // This could be returned from the CMS in the future.

const SurveyContainerPage = ({ content }) => {
	const apiUrl = content.apiUrl || '/sv/api/v1.0/survey';
	const axiosInstance = axios.create({
		baseURL: apiUrl,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'content-type': 'application/json; charset=utf-8',
		},
	});
	axiosInstance.CancelToken = axios.CancelToken;

	return (
		<AxiosContext.Provider
			value={{
				axiosInstance,
			}}
		>
			<SurveyRouter basePath={content.baseUrl || surveyUrl} />
		</AxiosContext.Provider>
	);
};

export default SurveyContainerPage;
