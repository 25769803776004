export const colors = {
	white: '#fff',
	whiteOpacity50: 'rgba(255, 255, 255, 0.5)',
	witeOpacity5: 'rgba(255, 255, 255, 0.05)',
	black: '#231F20',
	blackOpacity90: 'rgba(0, 0, 0, 0.9)',
	grey: '#A3A19D',
	greyLight: '#E2E2E2',
	greyLighter: '#F4F4F4',
	greyDark: '#767676',
	pink: '#EE63A1',
	green: '#008a2b',
	greenLight: '#779F32',
	magenta: '#DD2879',
	teal: '#00636A',
	orange: '#E05B27',
	purple: '#6B2879',
	blue: '#4a90e2',
	shadow: 'rgba(122, 122, 122, 1)',
	yellow: '#EFD915',
};
