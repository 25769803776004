import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const buttonBaseStyle = css`
	font-size: 1.8rem;
	line-height: 1.6rem;
	padding: 1.3rem;
	border-radius: 0.2rem;
	vertical-align: middle;
	text-decoration: none;

	&:focus,
	&:hover {
		border: 0.2rem solid ${({ theme }) => theme.colors.white};
		box-shadow: 0 0 0 0.4rem ${({ theme }) => theme.colors.blue};
		border-radius: 0.005rem;
		margin: 0.4rem;
		padding: 0.9rem;
	}

	&.disabled,
	&:disabled {
		opacity: 0.3;
	}

	& svg {
		vertical-align: middle;
		margin-top: -0.2rem;
	}

	& * {
		pointer-events: none;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 8.2rem 3rem;

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceMediumMin}) {
		margin-left: 6rem;
		margin-right: 6rem;
	}

	@media (max-width: ${({ theme }) => theme.mediaQueries.deviceSmallMax}) {
		margin-left: 0;
		margin-right: 0;
	}
`;

export const Button = styled.button`
	${buttonBaseStyle}
	background-color: ${({ theme }) => theme.colors.green};
	border: 0.2rem solid ${({ theme }) => theme.colors.green};
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;

	& * {
		pointer-events: none;
	}
`;

export const ButtonLink = styled(Link)`
	${buttonBaseStyle}
	background-color: ${({ theme }) => theme.colors.green};
	border: 0.2rem solid ${({ theme }) => theme.colors.green};
	color: ${({ theme }) => theme.colors.white};
`;

export const ButtonTextLink = styled(Link)`
	${buttonBaseStyle}
	color: ${({ theme }) => theme.colors.teal};
	border: 0.2rem solid #F7F7F7;
`;
