/**
 * IdentificationPage
 * @module components/IdentificationPage
 */

import React, { useState, useContext } from 'react';
import { generatePath } from 'react-router';
import { useParams, useHistory } from 'react-router-dom';
import Meta from 'applications/survey/components/Meta';
import { useTrackPageTime, useCookie } from 'applications/survey/hooks';
import IdentificationForm from 'applications/survey/components/IdentificationForm';
import { AxiosContext } from 'applications/survey/context/axios.context';
import { H1, HtmlText } from 'applications/survey/components/Typography';
import { Header } from 'applications/survey/components/Layout';
import { LoaderFixed } from 'applications/survey/components/Loader';

interface Props {
	dbId: number;
	surveyId: number;
	basePath: string;
	onSurveyComplete: any;
	header: string;
	description: string;
	title: string;
}

const IdentificationPage: React.FC<Props> = props => {
	const {
		dbId,
		basePath,
		onSurveyComplete,
		header,
		description,
		title,
		surveyId,
	} = props;
	const { surveyId: surveyPathName } = useParams();
	const history = useHistory();
	const { axiosInstance } = useContext(AxiosContext);
	// eslint-disable-next-line
	const [timeTracked, setTrackTime, getTimeSummary] = useTrackPageTime();
	// eslint-disable-next-line
	const [compareOrgType, setCompareOrgType] = useCookie('compare-type', '');
	// eslint-disable-next-line
	const [compareOrgSize, setCompareOrgSize] = useCookie('compare-size', '');
	const [saving, setSaving] = useState(false);

	const pushToGTM = (data: any) => {
		const { pages, total } = getTimeSummary('startpage');

		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'eventTracking',
				category: 'Form submit',
				action: 'Identification form',
				label: data.organizationName
					? `organizationType: ${data.organizationType}, organizationSize: ${data.organizationSize}, organizationName: ${data.organizationName}`
					: `organizationType: ${data.organizationType}, organizationSize: ${data.organizationSize}`,
			});
			window.dataLayer.push({
				event: 'eventTracking',
				category: 'Form submit',
				action: 'Survey complete',
				label: '',
			});

			for (let key in pages) {
				if (pages.hasOwnProperty(key)) {
					window.dataLayer.push({
						event: 'timerTracking',
						timingCategory: 'Chapter',
						timingVar: 'duration',
						timingLabel: key,
						timingValue: pages[key],
					});
				}
			}

			window.dataLayer.push({
				event: 'timerTracking',
				timingCategory: 'Total',
				timingVar: 'duration',
				timingLabel: '',
				timingValue: total,
			});
		}
	};

	const onSubmit = (data: any): void => {
		setSaving(true);
		setCompareOrgType(data.organizationType);
		setCompareOrgSize(data.organizationSize);

		// Lock the survey
		data.status = 100;

		axiosInstance
			.put(`/${surveyId}`, data, {
				headers: { dbId },
			})
			.then(() => {
				pushToGTM(data);
				setSaving(false);
				onSurveyComplete();
				history.push(
					generatePath(basePath, {
						surveyId: surveyPathName,
						chapterId: 'resultat',
					})
				);
			})
			.catch((error: any) => {
				alert(error);
				setSaving(false);
			});
	};

	return (
		<>
			<Meta title={title}>
				<meta name="robots" content="noindex" />
			</Meta>
			<Header>
				<H1>{header}</H1>
				<HtmlText dangerouslySetInnerHTML={{ __html: description }} />
			</Header>
			<IdentificationForm onSubmit={onSubmit} />
			{saving && <LoaderFixed className="loader" />}
		</>
	);
};

export default IdentificationPage;
