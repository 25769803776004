/**
 * StartPage
 * @module pages/StartPage
 */

// import React from 'react';

const StartPage = ({ content }) => {
	return null;
	// return (
	// 	<main id="main-content">
	// 		<h1>{content.heading}</h1>
	// 		<p className="preamble">{content.preamble}</p>
	// 		<div dangerouslySetInnerHTML={{ __html: content.text }} />
	// 	</main>
	// );
};

export default StartPage;
