/**
 * For tracking/measure time spent on a page.
 * Returns the timer-object, a trackTime function and a summary function.
 *
 * @returns {Array}
 */

import { useCookie } from 'applications/survey/hooks';

export const useTrackPageTime = () => {
	const [timer, setTimer] = useCookie('timer', '{}');
	const timerCookie = JSON.parse(timer);

	const trackTime = (pathname?: string) => {
		if (!pathname) {
			return;
		}
		const { lastPage, lastTime, timers } = timerCookie;
		const currentTime = Date.now();
		const newTimer: any = { timers: { ...timers } };

		if (lastPage) {
			newTimer.timers[lastPage] =
				(newTimer.timers[lastPage] || 0) + (currentTime - lastTime);
		}
		newTimer.lastPage = pathname;
		newTimer.lastTime = currentTime;
		setTimer(JSON.stringify(newTimer));
	};

	const getTimeSummary = (excludePath?: string) => {
		const { lastPage, lastTime, timers } = timerCookie;
		const currentTime = Date.now();
		const allTimers: any = { timers: { ...timers } };
		let total = 0;

		if (allTimers.timers['startpage']) {
			delete allTimers.timers['startpage'];
		}

		if (lastPage) {
			allTimers.timers[lastPage] =
				(allTimers.timers[lastPage] || 0) + (currentTime - lastTime);
		}

		for (let key in allTimers.timers) {
			if (allTimers.timers.hasOwnProperty(key)) {
				total += allTimers.timers[key];
			}
		}

		document.cookie = 'timer=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

		return { pages: allTimers.timers, total };
	};

	return [timerCookie, trackTime, getTimeSummary];
};
